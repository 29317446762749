      <!-- 第三个功能 第二个页面 -->
<template>
    <div class="bookview-main-container">
      <div class="bookview-container">
        <div class="close-button" @click="closePage">
          <span id="close-page" class="close-icon" >&times;</span>
        </div>
        <div class="bookview-image-container">
          <img v-if="itemInfo.type === 'image'" :src="itemInfo.url" @click="openModal"/>
          <video controls v-if="itemInfo.type === 'video'" autoplay >
            <source :src="itemInfo.url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <span v-if="itemInfo.type === 'text'" class="scene-text" >{{itemInfo.content}}</span>
          <iframe v-if="itemInfo.type === 'link'" :src="itemInfo.url" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div id="myModal" class="modal" v-show="isModalOpen">
        <span class="close-modal" @click="closeModal">&times;</span>
        <div class="modal-content">
          <img class="modal-content-img" :src="itemInfo.url" />
        </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent,ref, PropType } from 'vue';
  import { SubMenuItem } from "@/net/linkardNet";
  
  export default defineComponent({
    props: {
      imageUrl: {
        type: String,
        default: '',
      },
      itemInfo:{
        type: Object as PropType<SubMenuItem>,
        default: () => ({}) 
      }
    },
    name: 'bookImageView',
    setup(props, { emit }) {
      const url = ref("https://admin.test.linkard.me/home");
      const type = ref("link");
      const isModalOpen = ref(false);
      const imageSrc = props.imageUrl;
      const closePage = () => {
        emit('closeBookImage');
      };
      const openModal = () => {
      isModalOpen.value = true;
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };
  
      return {
        url,
        imageSrc,
        closePage,
        closeModal,
        openModal,
        isModalOpen
      };
    }
  });
  </script>
  
  <style scoped>
  .bookview-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative; /* 确保子元素的绝对定位相对于此容器 */
  z-index: 1002; 
}

.bookview-container {
  text-align: center;
  flex-shrink: 0;
  background: #F0F0F0;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; /* 确保子元素的绝对定位相对于此容器 */
  z-index: 1002; 
}

.bookview-image-container {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002; 
}

.bookview-image-container img {
  width: 100%;
  border-radius: 10px;
  display: block;
  object-fit: contain;
}

.bookview-image-container video {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
}

.scene-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  white-space: pre-wrap;
}
  
  /* 关闭按钮样式 */
  .close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 1003; 
  }
  
  .close-icon {
    font-size: 20px;
    color: black;
    display: inline-block;
    z-index: 1003; 
  }

  .scene-html{
    width: 100%;
    height: 100%;
  }

  /* 模态框样式 */
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1003; 
}

.modal.v-show {
  display: block;
}

.modal-content {
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-img {
  margin: auto;
  display: block;
  width: 100%;
  max-height: 80%;
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}
  
  </style>