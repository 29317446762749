import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86be2392"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "switchList",
  class: "switchList-container"
}
const _hoisted_2 = {
  id: "readerView",
  class: "switchList-container"
}
const _hoisted_3 = {
  id: "scene-Interactive",
  class: "scene-Interactive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SceneSwitchList = _resolveComponent("SceneSwitchList")!
  const _component_BookReaderView = _resolveComponent("BookReaderView")!
  const _component_InteractiveMenu = _resolveComponent("InteractiveMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SceneSwitchList, {
        ref: "sceneSwitchList",
        onCloseSceneList: _ctx.hideInteractive,
        linkardId: _ctx.linkardId,
        onSceneClick: _ctx.sceneClick,
        sceneId: _ctx.currentSceneId
      }, null, 8, ["onCloseSceneList", "linkardId", "onSceneClick", "sceneId"])
    ], 512), [
      [_vShow, _ctx.isShowList == 1 ]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BookReaderView, {
        ref: "bookReaderView",
        onCloseBookReader: _ctx.hideInteractive,
        linkardId: _ctx.linkardId,
        onVoicePlayText: _ctx.voicePlayText,
        onCloseUmiSpeak: _ctx.closeUmiSpeak
      }, null, 8, ["onCloseBookReader", "linkardId", "onVoicePlayText", "onCloseUmiSpeak"])
    ], 512), [
      [_vShow, _ctx.isShowList == 2 ]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_InteractiveMenu, {
        ref: "interactiveMenu",
        onSendTexttoChat: _ctx.sendTexttoChat,
        onCloseSceneList: _ctx.hideInteractive,
        linkardId: _ctx.linkardId,
        chatMenuList: _ctx.chatMenuList,
        onShowVideoPaly: _ctx.showVideoPaly
      }, null, 8, ["onSendTexttoChat", "onCloseSceneList", "linkardId", "chatMenuList", "onShowVideoPaly"])
    ], 512), [
      [_vShow, _ctx.isShowList == 0 ]
    ])
  ], 64))
}