<template>
    <div ref="bubbleLottieAnRef" :class="['bubble', position]" :style="{ animationDelay: delay + 's' }" @click="sendTexttoChat">
        <img  class ="bubbleBg" :src="require('../../assets/image/bubble.png')">
        <text class="bubbleText">{{text}}</text>
    </div>
</template>
  
  <script  lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
  import lottie, { AnimationItem } from 'lottie-web';
 // import animationData from "@/assets/image/bubble.json";

  export default defineComponent({
    name: 'BubbleItem',
    props: {
      position: String,
      delay: Number,
      text:String
    },
    setup(props, { emit }) {
      const bubbleLottieAnRef = ref();
      let chatLottieAn: AnimationItem | null;
  
      const sendTexttoChat = (event: MouseEvent) => {
       emit('sendTexttoChat', props.text);
    //    console.log("sendTexttoChat");
    //    playOrPause(true);
      };
  
      const playOrPause = (play: boolean) => {
        // if (play) {
        //   chatLottieAn?.play();
        // } else {
        //   chatLottieAn?.stop();
        // }
      };
  
      onMounted(() => {
        // const options = {
        //   animationData: animationData,
        //   loop: true,
        //   autoplay: true
        // };
  
        // chatLottieAn = lottie.loadAnimation({
        //   container: bubbleLottieAnRef.value,
        //   renderer: 'svg',
        //   ...options
        // });
      });
  
      onBeforeUnmount(() => {
        // if (chatLottieAn) {
        //   chatLottieAn.stop();
        //   chatLottieAn.destroy();
        //   chatLottieAn = null;
        // }
      });
  
      return {
        sendTexttoChat,
        playOrPause,
        bubbleLottieAnRef
      };
    }
  })
  </script>
  
  <style scoped>
  .bubble {
    position: absolute;
    animation: fadeIn 1s forwards;
    width:100px;
    /* height: 50px; */
    pointer-events: auto;
  }
   .bubbleBg{
    width: 100%;
   }

  .bubbleText{
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    color: black;
    font-size: 10px;
    z-index: 3001;
    transform: translate(-50%,-50%) scale(1); /* 初始状态为正常大小 */
    animation: scaleText 1.5s ease-in-out infinite; 
    animation-delay: 1s; /* 等待气泡淡入后再开始缩放 */
  }
  
  @keyframes scaleText {
  0%, 100% {
    transform: translate(-50%,-50%) scale(0.9); /* 正常大小 */
  }
  50% {
    transform: translate(-50%,-50%) scale(1.1); /* 放大到1.1倍 */
  }
}

  /* .bubble::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  } */
  
  .top-left {
    top: 12%;
    left: 8%;
  }
  
  .bottom-left {
    top: 30%;
    left: 1%;
  }

  .top-left2 {
    bottom: 48%;
    left: 2%;
  }

  .top-right {
    top: 18%;
    right: 8%;
  }
  
  .bottom-right {
    bottom: 43%;
    right: 2%;
  }

  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  </style>