<template>
  <div
    :class="['umi_audio_info_bg', 'umi_audio_info_speaking_bg' ]">
    <!-- <div v-if="speakInfoSendState === 1" class="center_time_info">
      <div class="umi_audio_lottie" ref="chatLottieAnLeftRef"></div>
      <span>{{ formattedTime }}</span>
      <div class="umi_audio_lottie" ref="chatLottieAnRightRef"></div>
    </div> -->
    <div v-if="speakInfoSendState === 3 || speakInfoSendState === 4" class="listen_tips">
      <span style="text-align: center;">{{ umiName }}正在{{ speakInfoSendState === 3 ? '思考' : '说话' }}中</span>
      <van-button class="close_button" @click.stop="closeUmiSpeak">
        <img :src="require('@/assets/image/umi_listen_close.svg')" class="close_button_img">
      </van-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import lottie, { AnimationItem } from 'lottie-web';
import animationData from "@/assets/image/umi_audio_ani.json";

export default defineComponent({
  props: {
    speakInfoSendState: {
      type: Number,
      default: -1
    },
    umiName: {
      type: String,
      default: ''
    },
  },
  emits: ['closeUmiSpeak', 'stopCountDownMethod'],
  setup(props, { emit }) {
    const chatLottieAnLeftRef = ref();
    const chatLottieAnRightRef = ref();

    let chatLottieAnL: AnimationItem | null;
    let chatLottieAnR: AnimationItem | null;

    const seconds = ref(60);
    let timerInterval = 0;

    const formattedTime = computed(() => {
      return padNumber(seconds.value);
    });

    const startStop = () => {
      if (seconds.value > 0) {
        timerInterval = setInterval(updateTimer, 1000);
      }
    };

    const closeUmiSpeak = () => {
      emit("closeUmiSpeak");
    };

    const updateTimer = () => {
      if (seconds.value > 0) {
        seconds.value--;
      } else {
        if (timerInterval) {
          clearInterval(timerInterval);
          timerInterval = 0;
        }
        emit("stopCountDownMethod");
        // Timer has reached 0, you can handle this event as needed.
      }
    };

    const padNumber = (number: number) => {
      return (number < 10 ? '0' : '') + number;
    };

    onMounted(() => {
      if (props.speakInfoSendState == 1) {
        startStop();
        const options = {
          animationData: animationData,
          loop: true,
          autoplay: true
        };
        chatLottieAnL = lottie.loadAnimation({
          container: chatLottieAnLeftRef.value,
          renderer: 'svg',
          ...options
        });

        chatLottieAnR = lottie.loadAnimation({
          container: chatLottieAnRightRef.value,
          renderer: 'svg',
          ...options
        });
      }
    });

    onBeforeUnmount(() => {
      if (timerInterval) {
        clearInterval(timerInterval);
        timerInterval = 0;
      }
      if (chatLottieAnL) {
        chatLottieAnL.stop();
        chatLottieAnL.destroy();
        chatLottieAnL = null;
      }
      if (chatLottieAnR) {
        chatLottieAnR.stop();
        chatLottieAnR.destroy();
        chatLottieAnR = null;
      }
    });

    watch(() => props.speakInfoSendState, (newValue, oldValue) => {
      if (newValue === 1) {
        startStop();
      }
    });

    return {
      chatLottieAnLeftRef,
      chatLottieAnRightRef,
      formattedTime,
      closeUmiSpeak
    };
  }
});
</script>

<style scoped lang="less">
.umi_audio_info_bg {
  width: 208px;
  height: 32px;
  border-radius: 16px;
  // background: #F3EC3F;
  // border: 2px solid #242424;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
  pointer-events: none; // 阻止点击、状态变化和鼠标指针变化：

  .center_time_info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
    pointer-events: none; // 阻止点击、状态变化和鼠标指针变化：
  }

  .listen_tips {
    color: #242424;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
    pointer-events: none; // 阻止点击、状态变化和鼠标指针变化：

    .close_button {
      position: absolute;
      right: 10px;
      width: 88px;
      height: 36px;
      border-radius: 20px;
      background: #F0F0F0;
      border: 2px solid #242424;
      pointer-events: auto; // 阻止点击、状态变化和鼠标指针变化：
    }

    .close_button_img {
      width: 20px;
      height: 20px;
    }
  }

  .umi_audio_lottie {
    width: 48px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
    pointer-events: none; // 阻止点击、状态变化和鼠标指针变化：

    :deep(svg) {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
      pointer-events: none; // 阻止点击、状态变化和鼠标指针变化：
    }
  }

  span {
    color: #242424;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin: 0 18px;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
    pointer-events: none; // 阻止点击、状态变化和鼠标指针变化：

  }
}

.umi_audio_info_speaking_bg {
  background: transparent;
  border: none;
  width: 100%;
  margin-left: 0;
}
</style>
