import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2811b4bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expansion-container" }
const _hoisted_2 = {
  class: "interactive_guidance",
  ref: "interactiveGuidanceRef"
}
const _hoisted_3 = { class: "click_tips" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "interactive_guidance_bg",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.interactiveGuidanceClick && _ctx.interactiveGuidanceClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, null, 512),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.tips ? _ctx.tips : '点击互动'), 1)
    ])
  ]))
}