<template>
  <div class="review_content_bg">
    <transition name="scale-transition">
      <div v-if="showReview" class="review_content">
        <!-- <button class="" @click="close">消失</button> -->
        <span v-if="showPrologue" class="prologue_text"></span>
        <div v-else class="problem_content" :key="componentKey">
          <div class="top_info_content">
            <div ref="problemBgRef" class="problem_bg">
              <span class="problem_title">{{ questionInfo?.qtype == 0 ? '选择题' : '判断题' }}</span>
              <!-- 单选题 -->
              <div class="problem_info_bg" v-if="questionInfo?.qtype == 0">
                <span ref="problemRef" v-html="questionInfo?.content" class="problem_info"></span>
                <div :class="['options_bg', questionInfo?.showAnalysis ? 'options_selected_bg' : '']">
                  <div v-for="(item, index) in questionInfo?.options" :class="getOptionClass(item)"
                    @click="optionClick(item)" v-html="item.key + '. ' + item.value"></div>
                </div>
              </div>
              <!-- 判断题 -->
              <div class="problem_info_bg" v-else="questionInfo?.qtype == 1"
                v-for="(option, index) in questionInfo?.options">
                <span ref="problemRef" v-html="option.value" class="problem_info"></span>
                <div :class="['judge_options_bg', questionInfo?.showAnalysis ? 'options_selected_bg' : '']">
                  <button :class="getJudgeOptionClass(option, index, 0)"
                    @click="judgeOptionClick(option, index, true)">对</button>
                  <button :class="getJudgeOptionClass(option, index, 1)"
                    @click="judgeOptionClick(option, index, false)">错</button>
                </div>
              </div>
            </div>
            <div v-show="questionInfo?.showAnalysis == true" class="analysis_bg">
              <div class="analysis_title">
                <img class="analysis_icon" src="../assets/image/question_analysis_icon.svg" alt="">
                <span class="analysis_title_text">错题解析</span>
              </div>
              <span ref="analysisRef"
                :class="['analysis_text', questionInfo?.showAnalysisD == true ? 'analysis_text_height' : '']"
                v-html="questionInfo?.analysis"></span>
            </div>
          </div>
          <div class="footer">
            <!-- <button class="footer_button back_button" @click="backButtonClick">
              <img :src="require('../assets/image/review_problem_back.svg')" />
            </button> -->
            <button class="footer_button next_button" @click="nextButtonClick">
              {{ questionInfo?.showAnalysis == true ? '学会啦下一个' : '完成啦下一个' }}
            </button>

          </div>
        </div>
      </div>
    </transition>
    <div v-show="showLottieView" class="lottie_animation_bg">
      <div class="lottie_animation" ref="lottieAnimationRef">
      </div>
      <!-- <div v-if="animationType == 1 || animationType == 3" class="answer_information">
        <span class="answer_information_label">总做题量：{{ totalNum }}</span>
        <span class="answer_information_label">总正确率：{{ correctRadio }}%</span>
      </div> -->
    </div>
    <Loading v-show="showLoading" />
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, onUnmounted, Ref, ref, watch } from 'vue';
import Loading from "../components/loading.vue";

import correctAnswer1 from "@/assets/animation//correct_answer/1.json";
import encourage1 from "@/assets/animation//encourage/1.json";
import endAnswer from "@/assets/animation//end_answer/1.json";

import { showImagePreview } from "vant";
import lottie, { AnimationItem } from 'lottie-web';
import { linkardNet, QuestionOption, RequestQuestionResponse } from '@/net/linkardNet';
import { showToast } from 'vant';


enum AnimationType {
  None = 0,
  CorrectAnswer = 1,
  Encourage = 2,
  EndAnswer = 3,
};

enum SpeakStatus {
  EndSpeak = 0,
  SpeaStart = 1,
}

enum SpeakContentStatus {
  None = 0,
  CorrectAnswer = 1,
  WrongAnswer = 2,
  AllAnswer = 3
}


export default defineComponent({
  name: 'ReviewExercisesView',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    reviewBookId: {
      type: String,
      default: "",
    },
    reviewCourseId: {
      type: String,
      default: "",
    },
    webrtcConnect: {
      type: Boolean,
      default: false,
    },
    canRead: {
      type: Boolean,
      default: false,
    },
    speakInfoSendState: {
      type: Number,
      default: 0,
    }
  },
  components: {
    Loading
  },
  computed: {
    // 使用 computed 动态计算类名
    getOptionClass() {
      return (item: QuestionOption) => {
        if (this.questionInfo) {
          if (this.selectOptions.includes(item)) {
            if (this.questionInfo?.showAnalysis) {
              if (this.questionInfo.answer.includes(item.key)) {
                return 'option option_select';
              } else {
                return 'option option_select_wrong';
              }
            } else {
              return 'option option_select';
            }
          } else {
            return 'option';
          }
        } else {
          return 'option';
        }
      };
    },
    getJudgeOptionClass() {
      return (option: QuestionOption, index: number, selIndex: number) => {
        if (this.questionInfo) {
          if (option.judge != undefined) {
            if (this.questionInfo?.showAnalysis && this.questionInfo.judgeAnswerList) {
              if (option.judge == true && selIndex == 0 && this.questionInfo.judgeAnswerList[index] == "T") {
                return 'judge_option judge_option_select';
              } else if (option.judge == true && selIndex == 0 && this.questionInfo.judgeAnswerList[index] == "F") {
                return 'judge_option judge_option_select_wrong';
              } else if (option.judge == false && selIndex == 1 && this.questionInfo.judgeAnswerList[index] == "T") {
                return 'judge_option judge_option_select_wrong';
              } else if (option.judge == false && selIndex == 1 && this.questionInfo.judgeAnswerList[index] == "F") {
                return 'judge_option judge_option_select';
              } else {
                return 'judge_option';
              }
            } else {
              if (option.judge == true && selIndex == 0) {
                return 'judge_option judge_option_select';
              } else if (option.judge == false && selIndex == 1) {
                return 'judge_option judge_option_select';
              } else {
                return 'judge_option';
              }
            }
          } else {
            return 'judge_option';
          }
        } else {
          return 'judge_option';
        }
      };
    },
    // computedAnalysisText() {

    // }
  },
  setup(props, { emit }) {
    const showReview = ref(false);
    const showPrologue = ref(false);
    const lottieAnimationRef = ref();
    const problemBgRef = ref<HTMLDivElement>();
    const analysisRef = ref<HTMLDivElement>();
    let lottieAnimation: AnimationItem | null;
    const questionInfo: Ref<RequestQuestionResponse | null> = ref(null);
    const componentKey = ref(0);
    let selectOptions: Ref<QuestionOption[]> = ref([]);
    const showLoading = ref(false);
    let answerCorrectCount = 0;
    let speakStatus: SpeakStatus = SpeakStatus.EndSpeak;
    let speakContentStatus: SpeakContentStatus = SpeakContentStatus.None;
    let answerWrongCount = 0;
    const correctList: Ref<string[]> = ref([]);
    const showLottieView = ref(false);
    let animationType: Ref<AnimationType> = ref(AnimationType.None);
    let answerFirst = true;
    let totalNum = ref(0);
    let correctRadio = ref(0);
    let requestCount = 0;
    const wrongAnswerList = [
      "答错了没关系，好好思考就能答对，我们开始下一题咯。",
      "别灰心，做题多用心肯定会进步，现在就做下一道题吧。",
      "做错没啥大不了，做题前认真想一下，我们接着做下一题啦。"
    ];

    const correctSpeakList = [
      "哟呵，又连续答对啦，太厉害了！继续大显身手，将下一道题也一举拿下吧！",
      "答对啦，答对啦，下一题会不会是你的 “手下败将” 呢？快去会会它！",
      "哈哈，连对好几题，你这是要冲向 “答题宇宙无敌” 的节奏啊！下一题，出发！"
    ];

    const allAnswerList = [
      "哇哦，所有题目都被你成功拿下，现在快去小程序里探索其他教材的题目，开启新一轮的挑战吧！",
      "哇哦，全部题目都被你收入囊中，简直太棒啦！赶紧回到小程序，还有更多其他教材的题目等着你来大显身手哦！",
      "哇哦，这些题目都被你完美攻克，太了不起了。到小程序中去找其他教材的题目练习，继续展现你超强的学习实力！"
    ];

    watch(
      () => props.webrtcConnect,
      (newValue, oldValue) => {

      }
    );

    watch(
      () => props.speakInfoSendState,
      (newValue, oldValue) => {
        speakStatus = newValue;
        if (speakStatus == SpeakStatus.EndSpeak && answerFirst && questionInfo.value) {
          answerFirst = false;
          if (questionInfo.value?.qtype == 1) {
            voicePlayTextRequest("请判断下列说法是否正确", true, false);
          } else {
            voicePlayTextRequest(questionInfo.value.rawText, true, false);
          }
        } else if (speakStatus == SpeakStatus.EndSpeak && speakContentStatus == SpeakContentStatus.CorrectAnswer) {
          speakContentStatus = SpeakContentStatus.None;
          closeAnimation();
          getNextProblemRequest();
        } else if (speakStatus == SpeakStatus.EndSpeak && speakContentStatus == SpeakContentStatus.WrongAnswer) {
          speakContentStatus = SpeakContentStatus.None;
          closeAnimation();
          getNextProblemRequest();
        }
      }
    );

    onMounted(async () => {
      getNextProblemRequest();
    });

    onUnmounted(() => {
    });

    const close = () => {
      showReview.value = false;
      setTimeout(() => {
        emit('closeReviewExercisesView');
      }, 300);
    };

    const backButtonClick = () => {
      startAnimation(AnimationType.CorrectAnswer);
    };

    const nextButtonClick = () => {
      // startAnimation(AnimationType.EndAnswer);
      // getStudentAnswerRequest();
      if (questionInfo.value?.showAnalysis == true) {
        if (answerWrongCount >= 3) {
          answerWrongCount = 0;
          startAnimation(AnimationType.Encourage);
          const random = getRandomNumber(3);
          voicePlayTextRequest(wrongAnswerList[random], true, false);
          setTimeout(() => {
            speakContentStatus = SpeakContentStatus.WrongAnswer;
          }, 100);
        } else {
          getNextProblemRequest();
        }
      } else {
        submitQuestionRequest();
      }
    };

    const optionClick = (item: QuestionOption) => {
      if (questionInfo.value?.showAnalysis == true || selectOptions.value.includes(item)) return;
      selectOptions.value = [];
      selectOptions.value.push(item);
    };

    const judgeOptionClick = (item: QuestionOption, index: number, judge: boolean) => {
      if (questionInfo.value) {
        if (questionInfo.value.showAnalysis == true) return;
        const info = questionInfo.value.options[index];
        info.judge = judge;
      }
    };

    const getNextProblemRequest = () => {
      selectOptions.value = [];
      if (problemBgRef.value) {
        problemBgRef.value.scrollTop = 0;
      }
      if (questionInfo.value != null) {
        showLoading.value = true;
      }
      linkardNet.requestQuestion(props.reviewBookId, props.reviewCourseId).then(
        (response: RequestQuestionResponse[]) => {
          showLoading.value = false;
          requestCount += 1;
          if (questionInfo.value) {
            questionInfo.value.showAnalysis = false;
            questionInfo.value.showAnalysisD = false;
          }
          if (response != undefined && response.length > 0) {
            const qrInfo = response[0];
            qrInfo.content = formatMathString(qrInfo.content);
            qrInfo.analysis = formatMathString(qrInfo.analysis);
            qrInfo.answer = formatMathString(qrInfo.answer);

            qrInfo.options = qrInfo.options.sort((a, b) => a.order - b.order);

            qrInfo.options.forEach(element => {
              element.value = formatMathString(element.value);
            });
            if (qrInfo.qtype == 0) {
              qrInfo.analysis = `正确答案：${qrInfo.answer} <br> ${qrInfo.analysis}`
            }
            else if (qrInfo.qtype == 1) {
              qrInfo.judgeAnswerList = qrInfo.answer.split(',');
              const ans = qrInfo.judgeAnswerList.map((item) => item === 'T' ? '对' : '错').join(',');

              qrInfo.analysis = `正确答案：${ans} <br> ${qrInfo.analysis}`
            }

            questionInfo.value = qrInfo;
            showReview.value = true;
            updateMathJax();

            setTimeout(() => {
              convertWidthHeightToRem();
            }, 200);

            setTimeout(() => {
              if (questionInfo.value && questionInfo.value.rawText && questionInfo.value.rawText != undefined) {
                if (props.canRead) {
                  if (questionInfo.value?.qtype == 1) {
                    voicePlayTextRequest("请判断下列说法是否正确", true, false);
                  } else {
                    voicePlayTextRequest(questionInfo.value.rawText, true, false);
                  }
                }
              }
            }, 200);
            if (requestCount == 1) {
              emit('hasQuestionInfo', 2);
            }
          } else {
            showToast("暂无题目啦~");
            showReview.value = false;
            startAnimation(AnimationType.EndAnswer);
            const random = getRandomNumber(3);
            voicePlayTextRequest(allAnswerList[random], true, false);
            if (requestCount == 1) {
              emit('hasQuestionInfo', 0);
            }else {
              emit('hasQuestionInfo', 1);
            }
          }
        },
        (ex) => {
          showLoading.value = false;
        }
      );
    };

    const getStudentAnswerRequest = () => {
      linkardNet.getStudentAnswer(props.reviewBookId, props.reviewCourseId).then(
        (response: any) => {
          if (response && response != undefined && response.totalNum > 0) {
            correctRadio.value = response.progress = Math.floor((response.correctNum / response.totalNum) * 100);
            totalNum.value = response.totalNum;
          }
        },
        (ex) => {

        }
      );
    };

    const submitQuestionRequest = () => {
      let args = {};
      let answerListStr = '';
      //单项选择题
      if (questionInfo.value?.qtype == 0) {
        if (selectOptions.value.length <= 0) {
          showToast("请选择答案");
          return;
        }
        const option = selectOptions.value[0];
        const tempCorrect = option.key == questionInfo.value.answer;
        args = {
          qid: questionInfo.value.qid,
          key: option.key,
          correct: tempCorrect ? "T" : "F"
        };
      } else if (questionInfo.value?.qtype == 1) {
        const tempAnswerList = [];
        correctList.value = [];
        for (let index = 0; index < questionInfo.value.options.length; index++) {
          const element = questionInfo.value.options[index];
          if (element.judge == undefined) {
            showToast('有题目尚未选择答案');
            return;
          }
          if (element.judge == true) {
            tempAnswerList.push('T');
          } else {
            tempAnswerList.push('F');
          }

          if (questionInfo.value.judgeAnswerList) {
            if (element.judge == true && questionInfo.value.judgeAnswerList[index] == "T") {
              correctList.value.push('T');
            } else if (element.judge == false && questionInfo.value.judgeAnswerList[index] == "F") {
              correctList.value.push('T');
            } else {
              correctList.value.push('F');
            }
          }
        }

        args = {
          qid: questionInfo.value.qid,
          key: answerListStr = tempAnswerList.join(","),
          correct: correctList.value.join(",")
        };
      }
      showLoading.value = true;
      linkardNet.submitQuestion(args).then(
        (_) => {
          showLoading.value = false;
          const option = selectOptions.value[0];
          let tempCorrect = true;
          if (questionInfo.value?.qtype == 0) {
            tempCorrect = option.key == questionInfo.value?.answer;
          } else if (questionInfo.value?.qtype == 1) {
            tempCorrect = answerListStr == questionInfo.value?.answer
          }
          handleSubmitQuestionResult(tempCorrect);
        },
        (ex) => {
          showLoading.value = false;
        }
      );
    };

    const handleSubmitQuestionResult = (correct: boolean) => {
      if (correct) {
        answerCorrectCount += 1;
        answerWrongCount = 0;
        if (answerCorrectCount >= 3) {
          answerCorrectCount = 0;
          // 恭喜
          startAnimation(AnimationType.CorrectAnswer);
          const random = getRandomNumber(3);
          voicePlayTextRequest(correctSpeakList[random], true, false);
          setTimeout(() => {
            speakContentStatus = SpeakContentStatus.CorrectAnswer;
          }, 100);
        } else {
          getNextProblemRequest();
        }
      } else {
        answerWrongCount += 1;
        answerCorrectCount = 0;

        if (questionInfo.value) {
          questionInfo.value.showAnalysis = true;
          setTimeout(() => {
            if (questionInfo.value) {
              questionInfo.value.showAnalysisD = true;

              setTimeout(() => {
                convertAnalysisWidthHeightToRem();
              }, 200);
            }
          }, 100);
        }
      }
    };

    const updateMathJax = () => {

      if (questionInfo.value) {
        let res = false;
        for (let index = 0; index < questionInfo.value.options.length; index++) {
          const element = questionInfo.value.options[index];
          if (element.value.includes("$")) {
            res = true;
            break;
          }
        }

        const content = questionInfo.value?.content;
        if (
          (content && (
            content.includes("$") ||
            content.includes("\\)") ||
            content.includes("\\]")
          )) || res || questionInfo.value?.answer.includes("$")
        ) {
          nextTick(() => {
            window.MathJax.typesetPromise();
          });
        }
      }
    };

    const startAnimation = (type: AnimationType) => {
      showLottieView.value = true;
      animationType.value = type;
      let data: any = null;
      switch (type) {
        case AnimationType.CorrectAnswer:
          data = correctAnswer1;
          break;

        case AnimationType.Encourage:
          data = encourage1;
          break;

        case AnimationType.EndAnswer:
          data = endAnswer;
          break;
      };
      const options = {
        animationData: data,
        loop: false,
        autoplay: true
      };
      lottieAnimation = lottie.loadAnimation({
        container: lottieAnimationRef.value,
        renderer: 'svg',
        ...options
      });
      // setTimeout(() => {
      //   closeAnimation();
      // }, 1000);
    };

    const closeAnimation = () => {
      if (lottieAnimation) {
        lottieAnimation.stop();
        lottieAnimation.destroy();
        lottieAnimation = null;
        showLottieView.value = false;
      }
    };

    const getRandomNumber = (random: number): number => {
      return Math.floor(Math.random() * random);
    };

    const formatMathString = (input: string): string => {
      // 替换 $$ 为 $
      let result = input.replace(/\$\$/g, '$');
      // 去掉 ;zk;
      result = result.replace(/;zk;/g, ',');
      return result;
    };

    const convertWidthHeightToRem = () => {
      if (problemBgRef.value) {
        const elements = problemBgRef.value.querySelectorAll('img');

        elements.forEach((el) => {
          const width = el.getAttribute('width');
          const height = el.getAttribute('height');

          if (width && !isNaN(Number(width))) {
            if (Number(width) > 200) {
              el.style.width = convertPxToRem(200);
            } else {
              el.style.width = convertPxToRem(Number(width));
            }
          }

          if (height && !isNaN(Number(height))) {
            if (Number(width) > 200) {
              el.style.height = convertPxToRem(200 * Number(height) / Number(width));
            } else {
              el.style.height = convertPxToRem(Number(height));
            }
          }
        });
      }
    };

    const convertAnalysisWidthHeightToRem = () => {
      if (analysisRef.value) {
        const elements = analysisRef.value.querySelectorAll('img');

        elements.forEach((el) => {
          const width = el.getAttribute('width');
          const height = el.getAttribute('height');

          if (width && !isNaN(Number(width))) {
            if (Number(width) > 200) {
              el.style.width = convertPxToRem(200);
            } else {
              el.style.width = convertPxToRem(Number(width));
            }
          }

          if (height && !isNaN(Number(height))) {
            let tempWidth = Number(width);
            if (Number(width) > 200) {
              el.style.height = convertPxToRem(200 * Number(height) / Number(width));
            } else {
              el.style.height = convertPxToRem(Number(height));
            }
          }
        });
      }
    };

    const convertPxToRem = (px: number) => {
      const rootValue = 26;  // 假设 1rem = 16px
      return `${px / rootValue}rem`;
    };

    const voicePlayTextRequest = (
      text: string,
      start: boolean,
      finish: boolean
    ) => {
      emit("voicePlayText", text, start, finish);
    };


    return {
      showReview,
      showPrologue,
      lottieAnimationRef,
      questionInfo,
      componentKey,
      selectOptions,
      showLoading,
      problemBgRef,
      analysisRef,
      showLottieView,
      totalNum,
      correctRadio,
      animationType,
      close,
      backButtonClick,
      nextButtonClick,
      optionClick,
      judgeOptionClick
    };
  }
});
</script>

<style lang="less" scoped>
.review_content_bg {
  width: 100%;
  left: 0;
  bottom: 0;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .review_content {
    position: relative;
    margin-bottom: 36px;
    width: calc(100% - 60px);
    min-height: 160px;
    max-height: calc(100% - 40px);
    padding: 20px;
    background-color: #ffffff;
    pointer-events: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .prologue_text {
      height: 100%;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      color: #242424;
    }

    .problem_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      /* 让其填充剩余空间 */

      .top_info_content {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        /* 让其占用更多的空间 */

        .problem_bg {
          width: 100%;
          // max-height: 33vh;
          max-height: 280px;
          border: none;
          overflow-y: scroll;

          .problem_title {
            font-size: 16px;
            color: #000000;
            font-weight: 600;
          }

          .problem_info_bg {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          .problem_info {
            width: 100%;
            // overflow-y: auto;
            min-height: 35px;
            margin: 10px 0;
            // padding: 6px;
            font-size: 14px;
            line-height: 20px;
            text-align: left !important;
            color: #242424;
            letter-spacing: 2px;
            // flex-shrink: 0; /* 防止被挤压 */
            flex-grow: 1;
            /* 设置字母之间的间隔 */

            u {
              text-decoration: underline !important;
            }

            :deep(p) {
              text-align: left !important;
            }
          }
        }

        .problem_bg::-webkit-scrollbar-track {
          background-color: transparent;
        }

        .problem_bg::-webkit-scrollbar {
          width: 0;
        }

        .problem_bg::-webkit-scrollbar-thumb {
          width: 0;
          background-color: transparent;
          border-radius: 0;
        }

        .options_bg {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          border: none;

          .option {
            background-color: #f8f8fd;
            font-size: 14px;
            color: #242424;
            width: calc(100% - 50px);
            border: 1px solid transparent;
            border-radius: 20px;
            text-align: left;
            padding: 8px 20px;
            margin-top: 10px;

            :deep(p) {
              display: inline;
            }
          }

          .option_select {
            background-color: rgba(116, 201, 98, 0.2);
            color: #74c962;
            border: 1px solid #74c962;
          }

          .option_select_wrong {
            background-color: rgba(229, 52, 39, 0.2);
            color: #e53427;
            border: 1px solid #e53427;
          }
        }

        .options_selected_bg {
          opacity: 0.6;
        }

        .judge_options_bg {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .judge_option {
            background-color: #ffffff;
            font-size: 15px;
            color: #000000;
            font-weight: 600;
            margin: 0 10px;

            width: 40px;
            height: 40px;
            border: 1px solid #242424;
            border-radius: 20px;
          }

          .judge_option_select {
            background-color: #74c962;
            border: 1px solid #74c962;
          }


          .judge_option_select_wrong {
            background-color: #e53427;
            border: 1px solid #e53427;
          }
        }

        .analysis_bg {
          width: calc(100% - 18px);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 16px;
          padding: 16px 12px;
          border-radius: 10px;
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
          /* 水平和垂直偏移为 0，模糊 15px */

          .analysis_title {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .analysis_icon {
              width: 15px;
              height: 15px;
            }

            .analysis_title_text {
              margin-left: 6px;
              font-size: 14px;
              color: #000000;
            }
          }

          .analysis_text {
            min-height: 35px;
            // flex-shrink: 0; /* 防止被挤压 */
            width: 100%;
            max-height: 0;
            margin-top: 10px;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: #242424;
            overflow-y: scroll;
            transition: max-height 300ms ease;
          }

          .analysis_text_height {
            // max-height: 30vh;
            max-height: calc(100vh - 500px);
          }
        }
      }

      .footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .footer_button {
          height: 34px;
          color: #242424;
          font-size: 12px;
          font-weight: 600;
          background-color: #F3EC3F;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .back_button {
          width: 34px;
          border-radius: 20px;
          margin-right: 10px;

          .image {
            width: 24px;
          }
        }

        .back_button:active {
          opacity: 0.6;
        }

        .next_button {
          padding: 0 14px;
          border-radius: 20px;
        }

        .next_button:active {
          opacity: 0.6;
        }
      }
    }
  }

  .lottie_animation_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // background-color: red;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;

    .lottie_animation {
      width: 290px;
      height: 290px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .answer_information {
      // width: 100px;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      z-index: 10;

      .answer_information_label {
        font-size: 12px;
        color: #ffffff;
        font-weight: 600;
        line-height: 16px;
        height: 16px;
      }
    }
  }
}

/* 进入动画 */
.scale-transition-enter-from {
  transform: scale(0.5);
  opacity: 0;
}

.scale-transition-enter-active {
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.scale-transition-enter-to {
  transform: scale(1);
  opacity: 1;
}

/* 离开动画 */
.scale-transition-leave-from {
  transform: scale(1);
  opacity: 1;
}

.scale-transition-leave-active {
  transition: transform 0.2s ease-in, opacity 0.2s ease-in;
}

.scale-transition-leave-to {
  transform: scale(0.5);
  opacity: 0;
}
</style>

<style>
mjx-merror[data-mjx-error] {
  background: inherit;
  /* color: inherit; */
}

mjx-math {
  font-size: 15px !important;
}

/* mjx-container mjx-merror[data-mjx-error]::before,
mjx-container mjx-merror[data-mjx-error]::after {
    content: '$';
    font-family: initial
}

mjx-container[display] mjx-merror[data-mjx-error]::before,
mjx-container[display] mjx-merror[data-mjx-error]::after {
    content: '$$';
    font-family: initial
} */
</style>
