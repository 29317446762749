<template>
    <div ref="imageContainer" class="image-container">
      <img v-for="(star, index) in stars" :key="index" :src="require('../../assets/image/star.png')" :style="star.style" class="star-image"/>
    </div>
  </template>
  
  <script>
  import { onMounted, ref, reactive, watchEffect } from 'vue';
  
  export default {
    setup() {
        const imageContainer = ref(null);
      const stars = ref([]);
      let intervalId;
  
      const generateRandomStars = (count,containerWidth,containerHeight) => {
        for (let i = 0; i < count; i++) {
          stars.value.push({
            style: {
                top: `${Math.random() * (containerHeight - 40)}px`, // 减去星星图片的高度
                left: `${Math.random() * (containerWidth - 40)}px`, // 减去星星图片的宽度
              animationDelay: `${Math.random() * 3}s` // 随机延迟，使动画错开
            }
          });
        }
      };
  
      const updateStarPositions = () => {
    //  console.log("updateStarPositions");

      const containerRect = imageContainer.value.getBoundingClientRect();
            const initialContainerWidth = containerRect.width;
      const initialContainerHeight = containerRect.height;
      stars.value = [];
        generateRandomStars(5,initialContainerWidth,initialContainerHeight);
    };

    onMounted(() => {
    });
  
      // 当组件卸载时清除定时器
      watchEffect(() => {
        if (!stars.value.length) {
          clearInterval(intervalId);
        }
      });
  
      return {
        stars,
        imageContainer,
        generateRandomStars,
        updateStarPositions
      };
    }
  };
  </script>
  
  <style scoped>
  .image-container {
    position: absolute;
    width: 100%;
    height: 100%; /* 使用视口单位 */
    outline: none;
    border: none;
  }
  
  .star-image {
    position: absolute;
    width: 40px; /* 星星图片的大小 */
    height: 40px; /* 星星图片的大小 */
    animation: fade 2.5s infinite alternate; /* 透明度变化动画 */
    outline: none;
    border: none;
  }
  
  @keyframes fade {
    0% {
    opacity: 0.05; /* 开始时的透明度 */
  }
  50% {
    opacity: 1; /* 中间时刻达到完全不透明 */
  }
  100% {
    opacity: 0.1; /* 结束时恢复到初始透明度 */
  }
  }
  </style>