      <!-- 第三个功能 第一个页面 -->
<template>
  <div class="bookImageView-container" v-show="isShowImage">
     <BookImageView   @closeBookImage="closeBookImage" :itemInfo = "selectedOption"></BookImageView>
  </div>

  <div class="interactive-menu"  v-show="!isShowImage">
    <div class="scroll-container"  ref="scrollContainerRef">
      <div class="scroll-content" :style="{ transform: `translateX(${scrollLeft}px)` }" ref="scrollContentRef">
        <button v-for="(menuItem, index) in validMenuItems" :key="index" @click="showOptionsMenu(index)" >
          {{ menuItem.name }}
        </button>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="isOptionsMenuVisible" class="options-menu" @click="hideOptionsMenu">
        <div class="options-list" @click.stop>
          <div v-for="(option, index) in optionsList" :key="index" @click="selectOption(option)">
            {{ option.name }}
          </div>
        </div>
      </div>
    </transition>
  </div>
  <Bubbles :chatMenuList = "chatMenuList" @sendTexttoChat = "sendTexttoChat"></Bubbles>
</template>

<script lang="ts">
import { defineComponent, ref,Ref,PropType,computed } from 'vue';
import { SubMenuItem,ChatMenuItem } from "@/net/linkardNet";
// StoryItem,Story,
import BookImageView from "./bookImageView.vue";
import Bubbles from './bubblesView.vue';

export default defineComponent({
  props: {
    linkardId: {
      type: String,
      default: '',
    },
    chatMenuList: {
      type: Array as PropType<any[]>,
    }
  },
  components: {
    BookImageView,
    Bubbles
  },
  name: 'InteractiveMenu',
  setup(props, { emit }) {
  //const menuItems = ['幻影穿梭术', '心灵感应波', '时空扭曲技', '幽影瞬移法', '你发现我了'];
   // const optionsList = ['Option A', 'Option B', 'Option C'];
    
    const isOptionsMenuVisible = ref(false);
    const selectedOption = ref<SubMenuItem>();
    const scrollLeft = ref(0);
    let isDragging = false;
    let startX = 0;
    const scrollContentRef = ref<HTMLElement | null>(null);
    const scrollContainerRef = ref<HTMLElement | null>(null);
    let isShowImage = ref(false);
    let optionsList: Ref<SubMenuItem[] > = ref([]);

    const validMenuItems = computed(() => {
      return (props.chatMenuList || []).filter((item): item is ChatMenuItem => {
        return item && typeof item.subMenuList != undefined && item.subMenuList?.length>0;
      });
    });

    const startDrag = (event: MouseEvent | TouchEvent) => {
      isDragging = true;
      startX = 'clientX' in event ? event.clientX : event.touches[0].clientX;
      document.addEventListener('mousemove', drag);
      document.addEventListener('touchmove', drag);
      document.addEventListener('mouseup', endDrag);
      document.addEventListener('touchend', endDrag);
    };

    const drag = (event: MouseEvent | TouchEvent) => {
      if (!isDragging) return;
      const currentX = 'clientX' in event ? event.clientX : event.touches[0].clientX;
      const diffX = currentX - startX;
      const newScrollLeft = scrollLeft.value + diffX;
      if (scrollContainerRef.value && scrollContentRef.value) {
        const maxScrollLeft = -(scrollContentRef.value.offsetWidth - scrollContainerRef.value.offsetWidth);
        scrollLeft.value = Math.max(Math.min(newScrollLeft, 0), maxScrollLeft);
      }
      startX = currentX;
    };

    const endDrag = () => {
      isDragging = false;
      document.removeEventListener('mousemove', drag);
      document.removeEventListener('touchmove', drag);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('touchend', endDrag);
    };

    const showOptionsMenu = (index: number) => {
      optionsList.value = (props.chatMenuList as ChatMenuItem[])[index].subMenuList;
      if(optionsList.value ==undefined|| optionsList.value.length==0){
        sendTexttoChat((props.chatMenuList as ChatMenuItem[])[index].name);
      }
      else if(optionsList.value.length==1){
        selectOption(optionsList.value[0]);
        //根据后端诉求，希望这个时候给后端发一下list的名字
        //sendTexttoChat(optionsList.value[0].name);
      }
      else{
        isOptionsMenuVisible.value = true;
      }
    };


    const hideOptionsMenu = () => {
      isOptionsMenuVisible.value = false;
    };

    const sendTexttoChat = (text:string) => {
      emit('sendTexttoChat',text);
    }

    const selectOption = (option: any) => {
      selectedOption.value = option;
      if( selectedOption.value?.type =="link")
      {
        window.location.href = selectedOption.value.url;
        
      }else if(selectedOption.value?.type =="video"){
        emit("showVideoPaly", selectedOption.value?.url);
        isOptionsMenuVisible.value = false;
      }
      else{
        hideOptionsMenu();
        isShowImage.value = true;
      }
    };


    const closeBookImage = () => {
      isShowImage.value = false;
    };

    return {
      optionsList,
      isOptionsMenuVisible,
      isShowImage,
      selectedOption,
      showOptionsMenu,
      hideOptionsMenu,
      selectOption,
      closeBookImage,
      sendTexttoChat,
      scrollLeft,
      scrollContentRef,
      scrollContainerRef,
      validMenuItems,
      startDrag,  // 添加 startDrag 方法
      drag,       // 添加 drag 方法
      endDrag,     // 添加 endDrag 方法
      
    };
  },
});
</script>

<style scoped>
.bookImageView-container{
   width:calc(100% - 56px);
   height:100%;
   padding:28px 28px 28px 28px;
   pointer-events: auto;
   display:flex;
   position:absolute;
   z-index: 3001;
}
.interactive-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-bottom: 80px;
  padding-left: 10px;
  z-index: 3001;
}

.scroll-container {
  display: flex;
  overflow-x: hidden;  /* 隐藏滚动条 */
  white-space: nowrap;
  width: 100%;  /* 确保宽度是 100% */
  position: relative;
  user-select: none;  /* 防止文本选中 */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  pointer-events: auto;
}

/* 隐藏滚动条（WebKit 浏览器） */
.scroll-content::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}

.scroll-content {
  display: inline-flex;
  position: relative;
  overflow-x: auto;
  transition: transform 0.3s ease;
}

button {
  margin-right: 6px;
  padding: 10px 20px; 
  width: 80px;
  height: 28px;
  border: none;
  border-radius: 32px;
  border: 2px solid var(--Black, #242424);
  background: #F3EC3F;
  color: black;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.options-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 3001;
  pointer-events: auto;
}

.options-list {
  background-color: white;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  border-radius: 40px 40px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.options-list > div {
  padding: 30px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
}

.options-list > div:last-child {
  border-bottom: none;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>