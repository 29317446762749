      <!-- 第二个功能 -->
<template>
   <AnimationSnow/>

  <div class="book-main-container">
    <div class="book-container">
      <div class="close-button">
        <span id="close-page" class="close-icon" @click="closePage">&times;</span>
      </div>

      <!-- 显示场景序列 -->
      <div v-show="isSelectedOpen" class="scene-grid">
              <div
                v-for="scene in currentStoryItem"
                :key="scene.id"
                class="scene-item"
                :data-id="scene.id"
                @click="handleSceneClick(scene)"
                :class="{ selected: false }"
              >
                <img :src="scene.coverUrl" :alt="`Scene ${scene.id}`" />
                <div class="scene-text">{{ scene.name }}</div>
              </div>
       </div>
 <div class="reader-container" v-show="!isSelectedOpen">
      <div class="page-title">
        <span id="page-title">{{ title }}</span>
      </div>
      <div class="book-image-container">
        <img v-if="currentImageSrc!=''"  :src="currentImageSrc" :alt="currentImageText" @click="openModal" @load="onImageLoaded"/>
        <AnimationStar ref="starView" class="star-animation"/>
      </div>
      <div class="text-container" :class="currentImageText.length < 20 ? 'center-text' : ''" >
        <p>{{ currentImageText }}</p>
      </div>
      <div class="navigation-buttons">
        <div class="page-number">
        <span id="page-number">{{ currentPage }}/{{ totalPages }}</span>
      </div>
        <button id="prev-button" class="nav-button" :disabled="currentPage === 1" @click="prevPage">
          <img style="transform: rotate(180deg);"  :src="require('../../assets/image/next_black_icon.svg')"/>
        </button>
        <button id="next-button" class="nav-button" :disabled="currentPage === totalPages" @click="nextPage(true)">
          <img  :src="require('../../assets/image/next_black_icon.svg')"/>
        </button>
      </div>
    </div>
    <!-- 模态框 -->
      <div id="myModal" class="modal" v-show="isModalOpen">
        <span class="close-modal" @click="closeModal">&times;</span>
        <div class="modal-content">
          <img v-if="currentImageSrc!=''" class="modal-content-img" :src="currentImageSrc" />
        </div>
      </div>
    </div>
  </div>
</template>

<script  lang="ts">
import { defineComponent,ref, computed, onMounted,Ref } from 'vue';
import { StoryItem,StorySectionItem,linkardNet } from "@/net/linkardNet";
import AnimationSnow from "./animationSnow.vue";
import AnimationStar from "./animationStar.vue";

interface BookInfo {
  src: string;
  text: string;
}

export default defineComponent ({
  props: {
    linkardId: {
      type: String,
      default: '',
    }
  },
  components: {
    AnimationSnow,
    AnimationStar
  },
  name: 'bookReaderView',
  setup(props, { emit }) {
    const scenes = ref([
    ]);

    const currentPage = ref(1);
    const isSelectedOpen = ref(true);
    const isModalOpen = ref(false);
    const bookImages: Ref<BookInfo[] >= ref([]);
    const currentStoryItem : Ref<StoryItem[] > = ref([]);
    const currentStoryInfo : Ref<StorySectionItem[] >= ref([]);
    const title = ref("");    
    const starView = ref();

    const currentImageSrc = computed(() => bookImages.value[currentPage.value - 1]?bookImages.value[currentPage.value - 1].src:"");
    const currentImageText = computed(() => bookImages.value[currentPage.value - 1]?bookImages.value[currentPage.value - 1].text:"");
    const totalPages = computed(() => bookImages.value.length);

    let voiceId = 0;
    let pageId = 0;

    const clearVoide =(needClose:boolean)=>{
      if (voiceId>0) {
        clearTimeout(voiceId);
        voiceId = 0;
        if(needClose){
          closeUmiSpeak();
        }
      }
      if(pageId>0){
        clearTimeout(pageId);
        voiceId = 0;
      }
    }

    const startDelayedAction = (action:any,timer:number) => {
      if (voiceId>0) {
        clearTimeout(voiceId);
      }
      voiceId = setTimeout(() => {
        action();
      }, timer);
    };

    const prevPage = () => {
      clearVoide(true);
      if (currentPage.value > 1) {
        currentPage.value--;
        playNext("prevPage");
      }
    };

    const nextPage = (clear:boolean) => {
      if(clear){
        clearVoide(true);
      }
      if (currentPage.value < totalPages.value) {
        playNext("nextPage");
        currentPage.value++;
      }
    };
    const playNext = (source:string)=>{
      startDelayedAction(()=>{emit("voicePlayText",bookImages.value[ currentPage.value-1].text, true, false);
        },2000);
    }

    const openModal = () => {
      isModalOpen.value = true;
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };

    const closePage = () => {
      clearVoide(true);
      emit('closeBookReader');
    };

    const updateQuery = () => {
      isSelectedOpen.value = true;
      currentPage.value = 1;
      getStoryListRequest();
    };

    const getStoryListRequest = () => {
      linkardNet.getStoryList(props.linkardId,1,50).then(
        (response) => {
          if (response&&response.list && response != undefined)
          {
            currentStoryItem.value = response.list;
          }
        },
        (ex) => { }
      );
    };

    const onImageLoaded =()=>{ 
      starView.value?.updateStarPositions();
    };

    const handleSceneClick =(scene:any)=>{ 
      currentPage.value = 1;
      title.value = scene.name;
      getStoryInfoRequest(scene.id);

    };

    const closeUmiSpeak =()=>{
      emit("closeUmiSpeak");
    };

    const getStoryInfoRequest = (storyId:string) => {
      linkardNet.getStoryInfo(props.linkardId,storyId).then(
        (response) => {
          currentStoryInfo.value = response.sectionList;
          bookImages.value=[];
          currentStoryInfo.value.forEach(item => {
               bookImages.value.push({ src: item.imageUrl, text: item.content });
           });
           isSelectedOpen.value = false;
           if(bookImages.value.length>0){
            startDelayedAction(()=>{ emit("voicePlayText",bookImages.value[0].text, true, false);
         
            },1000);
           }
        },
        (ex) => { }
      );
    };

    const nextAutoVoicePage = () => {
      //播放结束了，先

      if (pageId>0) {
        clearTimeout(pageId);
      }
      pageId = setTimeout(() => {
        nextPage(false);
      }, 2000);
    };

    onMounted(() => {
      updateQuery();
    });

    return {
      scenes,
      bookImages,
      currentPage,
      isModalOpen,
      currentStoryItem,
      isSelectedOpen,
      currentImageSrc,
      currentImageText,
      totalPages,
      title,
      starView,
      onImageLoaded,
      prevPage,
      nextPage,
      nextAutoVoicePage,
      openModal,
      closeModal,
      closePage,
      updateQuery,
      handleSceneClick
    };
  }
});
</script>

<style scoped>
.reader-container{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1001;
}
.book-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative; /* 确保子元素的绝对定位相对于此容器 */
  z-index: 1000;
}

.book-container {
  text-align: center;
  flex-shrink: 0;
  background: #F0F0F0;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; /* 确保子元素的绝对定位相对于此容器 */
}

.book-image-container {
  top: 40px;
  position: absolute;
  width: 280px; /* 图片容器宽度占满 */
  height: 210px; /* 图片容器高度占70% */
  display: flex;
  justify-content: center;
  object-fit: contain;
  z-index: 1001; 
  border-radius: 30px;
}

.book-image-container img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 30px;
  display: block;
}

.star-animation{
  /* position: fixed; */
  pointer-events: none;
  outline: none;
  border: none;
}

.text-container {
  margin-top: 4px; 
  top: calc(210px+ 42px);;
  position: absolute;
  /* margin-bottom: 20px; */
  width: 90%; /* 文字容器宽度占满 */
  height: 10%; /* 文字容器高度占20% */
  user-select: none;  /* 防止文本选中 */
}

.text-container p {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  text-align: left;    
  justify-content: flex-start; /* 或者使用这个属性使内容靠左 */
  color: #333;
  margin: 0;
}

.center-text p{
  text-align: center;
}

.navigation-buttons {
  width: 80%; /* 导航按钮容器宽度占满 */
  position: absolute;
  bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1002; 
}

.nav-button {
  /* padding: 10px 20px; */
  font-size: 10px;
  height: 44px;
  width: 64px;
  border-radius: 40px;
  background: #F0F0F0;
  /* BtnShadow */
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  color: black;
  cursor: pointer;
  flex-shrink: 0;

  transition: background 0.3s;
}

.nav-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.nav-button img{
  width: 12px;
  height: 12px;
}

/* .nav-button:hover:not(:disabled) {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  color: white;
} */

/* 模态框样式 */
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1003; 
}

.modal.v-show {
  display: block;
}

.modal-content {
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-img {
  margin: auto;
  display: block;
  width: 100%;
  max-height: 80%;
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

/* 页码样式 */
.page-title {
  position: absolute;
  left: 50%;
  top: 22px;
  max-width: 600px;
  transform: translate(-50%,-50%);
  /* background-color: black; */
  color: black;
  padding: 0px 13px;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 12px;
  z-index: 1002;
}

/* 页码样式 */
.page-number {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: black; */
  color: black;
  padding: 8px 13px;
  border-radius: 5px;
  font-size: 10px;
  z-index: 1002;
}

/* 关闭按钮样式 */
.close-button {
  transform: translateY(-50%);
  position: absolute;
  top: 22px;
  right: 20px;
  cursor: pointer;
  z-index: 1003; /* 确保关闭按钮在其他元素之上 */
}

.close-icon {
  font-size: 20px;
  color: black;
  display: inline-block; /* 确保 span 是块级元素 */
}

.scene-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, 100px);
  width: 95%;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  max-height: 90%;
  /*max-height: 250px;  根据需要调整高度 */
  overflow-y: auto; /* 添加垂直滚动条 */
  user-select: none;  /* 防止文本选中 */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.scene-item {
  position: relative;
  border-radius: 20px;
  background-color: #F0F0F0;
border: 6px solid white;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
}

.scene-item img {
  width: 100%;
  height:  100%;
  display: block;
  object-fit: cover;
}

.scene-text {
  position: relative;
  /* display: inline; */
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  max-width: 90%;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 6px 6px;
  box-sizing: border-box;
}

.scene-item.selected {

border: 6px solid var(--Yellow, #F3EC3F);
}

</style>