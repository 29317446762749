import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "Bubble" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BubbleItem = _resolveComponent("BubbleItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validMenuItems.slice(0, 5), (bubble, index) => {
      return (_openBlock(), _createBlock(_component_BubbleItem, {
        key: index,
        position: _ctx.positions[index%5],
        delay: _ctx.randomDelay[index%5],
        text: bubble.name,
        onSendTexttoChat: _ctx.sendTexttoChat
      }, null, 8, ["position", "delay", "text", "onSendTexttoChat"]))
    }), 128))
  ]))
}