<template>
  <div class="interactive_guidance_bg" @click="interactiveGuidanceClick">
    <div class="expansion-container">
      <div class="interactive_guidance" ref="interactiveGuidanceRef"></div>
      <span class="click_tips">{{tips ? tips : '点击互动'}}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import lottie, { AnimationItem } from "lottie-web";
import animationData from "@/assets/image/interactive_guidance.json";

export default defineComponent({
  props: {
    tips: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const interactiveGuidanceRef = ref();
    let interactiveGuidanceAn: AnimationItem | null;

    const interactiveGuidanceClick = () => {
      emit("closeInteractiveGuide");
    };

    onMounted(() => {
      const options = {
        animationData: animationData,
        loop: true,
        autoplay: true,
      };
      interactiveGuidanceAn = lottie.loadAnimation({
        container: interactiveGuidanceRef.value,
        renderer: "svg",
        ...options,
      });
    });

    onBeforeUnmount(() => {
      if (interactiveGuidanceAn) {
        interactiveGuidanceAn.stop();
        interactiveGuidanceAn.destroy();
        interactiveGuidanceAn = null;
      }
    });

    return {
      interactiveGuidanceRef,
      interactiveGuidanceClick,
    };
  },
});
</script>

<style scoped lang="less">
.interactive_guidance_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1003;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: auto;

  .expansion-container {
    position: absolute;
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 45%;
    left: 30%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .interactive_guidance {
      width: 40px;
      height: 40px;
      background-color: transparent;
      // position: absolute;
    }

    .click_tips {
      height: 30px;
      line-height: 30px;
      border-radius: 6px;
      padding: 0 4px;
      font-size: 12px;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
    }
  }
}
</style>
