import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a509b9f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "bubbleText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "bubbleLottieAnRef",
    class: _normalizeClass(['bubble', _ctx.position]),
    style: _normalizeStyle({ animationDelay: _ctx.delay + 's' }),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendTexttoChat && _ctx.sendTexttoChat(...args)))
  }, [
    _createElementVNode("img", {
      class: "bubbleBg",
      src: require('../../assets/image/bubble.png')
    }, null, 8, _hoisted_1),
    _createElementVNode("text", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ], 6))
}