<template>
  <div class="container">
    <div class="home-container" :style="{ backgroundImage: `url(${backgroundImg})` }" v-show="!showFavorites &&
      !showCabinet &&
      !showMessageBoardView &&
      !showEmptyCard
      ">
      <div class="linkard-config-tost" v-if="isMySelfPath">
        如需配置您的联卡，请使用电脑前往 www.linkard.me 开始配置
      </div>
      <div :class="sourceType == 1
        ? 'unity-iframe-container-minimalism'
        : 'unity-iframe-container'
        ">
        <iframe id="my-iframe" class="unity-iframe" :src="isHarmonyos ? LOCAL_UNITY_HM_URL_PATH : LOCAL_UNITY_URL_PATH"
          width="100%" height="100%" ref="unityIframe"></iframe>
      </div>
      <div v-show="!showChat && sourceType == 0" :class="['content', isMySelfPath ? 'content-pt0' : '']">
        <div class="content-wrapper">
          <div class="header-link">
            <span>
              <label>LINKARD</label>
            </span>
            <span></span>
            <span>linkard.me/{{ path }}</span>
          </div>
          <div class="card-collect-main">
            <div class="left_action_button">
              <div class="home-card-top-action-box" @click="toMyLinkard" v-if="!$route.query.preview">
                <van-image :src="require('../assets/image/icon-card.png')"></van-image>
                <span>我的联卡</span>
              </div>
              <div class="home-card-top-action-box card_box" @click="toMyFavorites" v-if="!$route.query.preview">
                <van-image :src="require('../assets/image/icon-card.png')"></van-image>
                <span>我的卡包</span>
              </div>
            </div>
            <div class="right_action_button">
              <div class="collect-box ta-click-collect" @click="handleCollect" v-show="!$route.query.preview && !isMySelfPath && !favoriteStatus
                ">
                收藏
              </div>
              <div class="collect-box had-collect ta-click-cancel-collect" @click="handleCancelCollect" v-show="!$route.query.preview && !isMySelfPath && favoriteStatus
                ">
                已收藏
              </div>

              <button v-if="cabinetValid && !$route.query.preview" class="cabinet_box" @click="toMyCabinet">
                <img :src="require('../assets/image/cabinet_box_icon.svg')" />
                <span>卡柜</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!showChat" class="bottom_info_bg">
        <div class="bar-con">
          <transition name="fade">
            <div class="talk-input" v-if="showWelcomeMsg">
              <span class="welcome_msg">{{ welcomeMsg }}</span>
            </div>
          </transition>
        </div>
        <div class="adver-chat-main">
          <div class="adver-box">
            <van-button v-if="!isOwn && userPhone" class="adver-box-item" @click="phoneCall">
              电话
            </van-button>
            <van-button v-if="(!isOwn && messageValid) || isOwn" class="adver-box-item" @click="toLeaveMessage">
              {{ isOwn ? "查看留言" : "留言" }}
            </van-button>
          </div>
          <ChatLottie ref="chatLottie" class="chat-box ta-click-to-chat" @click="toChat" :autoPlay="true"
            title="自由对话" />
        </div>
        <div class="content-middle">
          <div class="middle-bg">
            <a href="javascript:;">
              <img class="logo_img" :src="companyLogo || require('../assets/image/linkard_logo.png')
                " />
            </a>
            <div class="middle-content">
              <div class="title">{{ linkardName }}</div>
              <div class="btn-wrapper">
                <span v-for="(item, index) in linkardTags || []" :key="index">{{
                  item
                }}</span>
              </div>
              <div class="social-media-box" @click="showMediaClick">
                <span class="social-media-text">社媒聚合页</span>
                <div class="social_links_new" v-if="showSocialLinksNew">
                  <span class="social-media-icon"></span>
                  <span class="social-media-new">NEW</span>
                </div>
                <van-icon :size="$route.query.preview ? '10' : '30'" style="margin-left: 4px"
                  :name="require('../assets/image/social_media_arrow.svg')" />
              </div>
            </div>
          </div>
          <div :class="['space-box', spaceValid ? '' : 'no-space-box']" @click="handleSpaceVisit">
            <div class="space-box-3d">
              {{ linkardId ? spaceName : "暂未创建数字空间" }}
            </div>
            <van-image :src="spaceValid
              ? require('../assets/image/has_space_icon.svg')
              : require('../assets/image/no_space_icon.svg')
              "></van-image>
          </div>
        </div>
      </div>
      <MediaList v-if="showMedia" :linkardId="linkardId" :linksList="previewMediaList" @closeMedia="closeMediaDialog" />
      <ChatMessageList v-if="showChatMessageList" :linkardId="linkardId" :npcName="currentLKInfo?.npc.npcName"
        :sourceType="sourceType" @closeChatMessageListView="closeChatMessageListView" />
      <MyEvaluationList v-if="showMyEvaluationList" :linkardId="linkardId"
        @closeMyEvaluationListView="closeMyEvaluationListView" @showEvaluationDetailView="showEvaluationDetailView" />
      <EvaluationDetailView v-if="showEvaluationDetail" :evalReportId="evalReportId"
        @closeEvaluationDetailView="closeEvaluationDetailView" />

      <Chat v-show="showChat" ref="chatViewRef" :linkardId="linkardId" :showChat="showChat" :sdkToken="sdkToken"
        :micPermissions="micPermissions" :hasInitService="hasInitService" :initUnityCallBack="initUnityCallBack"
        :sourceType="sourceType" :npcName="currentLKInfo?.npc.npcName" :scaleValid="currentLKInfo?.scaleValid"
        :greetingMsg="currentLKInfo?.npc.greetingMsg" :scaleIconText="currentLKInfo?.scaleIconText"
        :chatSceneIconText="currentLKInfo?.chatSceneIconText" :storyIconText="currentLKInfo?.storyIconText"
        :chatMenuList="currentLKInfo?.chatMenuList" :reviewBookId="reviewBookId" :reviewCourseId="reviewCourseId"
        @closeChatView="closeChatView" @startCaptureAudioMethod="startCaptureAudioMethod"
        @stopCaptureAudioMethod="stopCaptureAudioMethod" @interruptPlayVoiceMethod="interruptPlayVoiceMethod"
        @setSendAudioEnableMethod="setSendAudioEnableMethod" @getMicPermissions="getMicPermissions"
        @showChatMessageListMethod="showChatMessageListMethod" @showMyEvaluationListMethod="showMyEvaluationListMethod"
        @sendTextToMouthCallBackMethod="sendTextToMouthCallBackMethod"
        @showEvaluationDetailView="showEvaluationDetailView" @resetRegisterSetChatParam="resetRegisterSetChatParam" />
    </div>
    <MyFavoritesComponent v-if="showFavorites && !$route.query.preview" @close="closeMyFavorites" />
    <MyLinkardListView v-if="showMyLinkardListView && !$route.query.preview" @close="closeMyLinkardListView" />
    <LeaveMessage v-if="showMessageBoardView && !$route.query.preview" @closeLeaveMessage="closeLeaveMessage"
      @goToPathFromMessageBoard="goToPathFromMessageBoard" :linkardId="linkardId" :isOwn="isOwn" />
    <Cabinet v-if="showCabinet" :linkardId="linkardId" :cabinetUserId="cabinetUserId" :cabinetId="cabinetId"
      @close="closeCabinet"></Cabinet>
    <EmptyCardComponent v-if="showEmptyCard && dataLoaded && !$route.query.preview" :isMySelf="isMySelfPath"
      @toMyLinkard="toMyLinkard" @toMyFavorites="toMyFavorites" />
    <Loading v-show="!dataLoaded" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { showConfirmDialog, showToast } from "vant";
import {
  RouteLocationNormalized,
  onBeforeRouteUpdate,
  useRoute,
  useRouter,
} from "vue-router";
import {
  AudioSdkLoginArgs,
  CurrentLKInfo,
  SocialLinksListInfo,
  linkardNet,
} from "@/net/linkardNet";
import { useWxShare } from "../hooks/useWxShare";
import { ACCESS_TOKEN, SMAL_TOKEN, WX_SHARE_LOGO } from "@/utils/const";

import Chat from "../components/chat.vue";
import Cabinet from "../components/cabinet.vue";
import MediaList from "../components/mediaList.vue";
import ChatLottie from "../components/chatLottie.vue";
import MyFavoritesComponent from "../components/MyFavorites.vue";
import MyLinkardListView from "../components/myLinkardListView.vue";
import LeaveMessage from "../components/leaveMessage.vue";
import EmptyCardComponent from "../components/EmptyCard.vue";
import ChatMessageList from "../components/chatMessageList.vue";
import MyEvaluationList from "../components/myEvaluationListView.vue";
import EvaluationDetailView from "../components/evaluationDetailView.vue";
import Loading from "../components/loading.vue";
import { useUserStore } from "@/stores/user";
import { buryingPointData } from "@/utils/buryingPointData";
import { uuid } from "vue3-uuid";

//最大聊天消息缓存联卡个数(即最多对60个联卡数据进行缓存，超出删除最久远的)
const MAXSAVECHATCOUNT = 60;
const WX_SERVER_URL = process.env.VUE_APP_WX_URL;
const REDIRECT_URL = process.env.VUE_APP_URL;
const SERVER_API_URL = process.env.VUE_APP_SERVER_URL;
const LOCAL_UNITY_URL_PATH = "/Unity/index.html";
const LOCAL_UNITY_HM_URL_PATH = "/Unity_HM/index.html";
const SDK_LOGIN_PACKAGE = "com.bennu";
const SDK_LOGIN_PACKAGE_TEST = "com.bennu.linkard";
const userAgent = navigator.userAgent.toLowerCase(); // 获取用户代理字符串
const isWeixin = userAgent.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
const isHarmonyos = userAgent.toLowerCase().indexOf("huawei") !== -1; // 鸿蒙

interface InitServiceCallBack {
  (token: string, userId: string, streamId: string): void;
}

interface RegisterSetChatParamCallBack {
  (npcId: string, voiceId: string, userId: string): void;
}

interface StartCaptureAudioCallBack {
  (audioRequstId: string): void;
}

interface StopCaptureAudioCallBack {
  (timeShort: boolean): void;
}

interface SendTextToMouthCallBack {
  (uid: string, text: string): void;
}

interface InterruptPlayVoiceCallBack {
  (): void;
}

interface SetSendAudioEnableCallBack {
  (enable: boolean): void;
}

interface RegisterSetReviewCourseCallBack {
  (courseIds: String[], resetHistory: boolean): void;
}

const composeLoginUrl = (path: string, params: any) => {
  const paramString = new URLSearchParams(params).toString();
  const encodedRedirectUrl = encodeURIComponent(
    `${REDIRECT_URL}/${path}?${paramString}`
  );
  return `${WX_SERVER_URL}/wechat/mp/redirectUrl?wxName=${process.env.VUE_APP_WX_APPNAME}&url=${encodedRedirectUrl}&scope=snsapi_userinfo`;
};

const checkLogin = (route: RouteLocationNormalized, params: any): boolean => {
  const hasToken = sessionStorage.getItem(ACCESS_TOKEN);
  if (isWeixin && !hasToken && !route.query.code && !route.query.preview) {
    const url = composeLoginUrl(String(route.query.path), params);
    window.location.replace(url);
    return false;
  }
  return true;
};

export default defineComponent({
  name: "Home",
  components: {
    Chat,
    ChatLottie,
    MediaList,
    MyFavoritesComponent,
    EmptyCardComponent,
    Loading,
    Cabinet,
    LeaveMessage,
    ChatMessageList,
    MyLinkardListView,
    MyEvaluationList,
    EvaluationDetailView,
  },

  beforeRouteEnter(to, from, next) {
    const cabinetId = to.query.cabinetId;
    let params = {};
    if (
      to.fullPath.indexOf("/cabinet?") != -1 &&
      cabinetId &&
      cabinetId != "undefined"
    ) {
      params = { cabinetId: cabinetId };
    }
    if (checkLogin(to, params)) {
      next();
    }
  },

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();

    const showChat = ref(false);
    const showMedia = ref(false);
    const showChatMessageList = ref(false);
    const showMyEvaluationList = ref(false);
    const showEvaluationDetail = ref(false);

    const linkardTags: Ref<string[]> = ref([]);
    const linkardId = ref("");
    const spaceValid = ref(true);
    const favoriteStatus = ref(false);
    const path = ref("");
    const companyLogo = ref("");
    const previewMediaList: Ref<SocialLinksListInfo[]> = ref([]);
    const linkardName = ref("");
    const welcomeMsg = ref("");
    const showWelcomeMsg = ref(false);
    const showFavorites = ref(false);
    const showMyLinkardListView = ref(false);
    const showSocialLinksNew = ref(false);
    const spaceName = ref("");
    const sdkToken = ref("");
    const micPermissions = ref(0); //0:尚未授权,1:已授权,-1:已拒绝
    const hasInitService = ref(false);
    const initUnityCallBack = ref(false);
    const showMessageBoardView = ref(false);
    const showCabinet = ref(false);
    const isOwn = ref(false);
    const cabinetValid = ref(false);
    const messageValid = ref(false);
    const userPhone = ref("");
    const toCabinetFromMessageBoard = ref(false);
    const cabinetUserId = ref("");
    const dataLoaded = ref(false);
    const currentLKInfo: Ref<CurrentLKInfo | undefined> = ref();
    const chatLottie = ref();
    const unityIframe = ref();
    const chatViewRef = ref();
    const sourceType = ref(-1); //1:大屏联卡，0:默认联卡
    const cabinetId = ref("");
    const evalReportId = ref("");
    const reviewBookId = ref("");
    const reviewCourseId = ref("");
    let evlDetailShowType = 1;

    let mySelfPath = "";
    let controlAdKeyShowTimer = 0;
    let setUnityTimer = 0;
    let voiceId = "";
    let npcId = "";
    let userId = "";
    let audioSdkLoginCount = 0;
    let streamId = "";
    let unityInstance = null;
    let characterFilePath: string | null = "";
    let characterBgImgUrl: string | null = "";

    const userStore = useUserStore();

    let initServiceCallBack: InitServiceCallBack | undefined = undefined;
    let registerSetChatParamCallBack: RegisterSetChatParamCallBack | undefined =
      undefined;
    let startCaptureAudioCallBack: StartCaptureAudioCallBack | undefined =
      undefined;
    let stopCaptureAudioCallBack: StopCaptureAudioCallBack | undefined =
      undefined;
    let interruptPlayVoiceCallBack: InterruptPlayVoiceCallBack | undefined =
      undefined;
    let setSendAudioEnableCallBack: SetSendAudioEnableCallBack | undefined =
      undefined;
    let registerSetReviewCourseCallback: RegisterSetReviewCourseCallBack | undefined =
      undefined;
    let sendTextToMouthCallBack: SendTextToMouthCallBack | undefined =
      undefined;

    cabinetId.value = String(route.query.cabinetId);
    if (
      route.fullPath.indexOf("/cabinet?") != -1 &&
      cabinetId.value &&
      cabinetId.value != "undefined"
    ) {
      showCabinet.value = true;
    }

    const handleChatSortedKeys = () => {
      const keys = Object.keys(localStorage);
      const filteredArray = keys.filter((element) =>
        element.includes("_chatMessage")
      );
      if (filteredArray.length > MAXSAVECHATCOUNT) {
        filteredArray.sort();
        var count = filteredArray.length - MAXSAVECHATCOUNT;
        filteredArray.forEach((element) => {
          if (count > 0) {
            localStorage.removeItem(element);
            count--;
          } else {
            return;
          }
        });
      }
    };
    const clearLinkardData = () => {
      dataLoaded.value = false;
      showFavorites.value = false;
      showChat.value = false;
    };

    const updateLinkardData = (data: any, from: string) => {
      companyLogo.value = data.icon;
      npcId = data.id;

      if (!route.query.preview) {
        voiceId = data.npc.voice ? data.npc.voice : "";
      }

      messageValid.value = data.messageValid;
      isOwn.value = data.isOwn;
      sourceType.value = data.sourceType;
      if (data.style == "chat" && sourceType.value != 1) {
        sourceType.value = 1;
      }
      if (sourceType.value == 1) {
        if (chatLottie.value) {
          chatLottie.value.playOrPause(false);
        }
        showChat.value = true;
        unityIframe.value.contentWindow.postMessage(
          { type: "changeLoading" },
          window.location.origin + LOCAL_UNITY_URL_PATH
        );
      }

      linkardTags.value =
        (data.tags &&
          data.tags.filter((tag: string) => tag !== "" && tag !== "无")) ||
        [];
      favoriteStatus.value = data.isFavorite;
      linkardName.value = data.name || "";
      buryingPointData.linkardName = linkardName.value;

      linkardId.value = data.id || "id_not_saved";
      userStore.linkardId = linkardId.value;
      spaceValid.value = data.spaceValid;
      if (spaceValid.value) {
        spaceName.value = data.name + "的数字空间";
      } else {
        spaceName.value = "暂未创建数字空间";
      }
      welcomeMsg.value = (data.npc && data.npc.welcomeMsg) || "";
      showSocialLinksNew.value = data.newTop || false;
      let tempFilePath = characterFilePath;
      if (
        data.npc &&
        data.npc.filepath &&
        characterFilePath != data.npc.filepath
      ) {
        characterFilePath = (data.npc && data.npc.filepath) || "";
        tempFilePath = characterFilePath;
      } else if (
        data.npc &&
        data.npc.filepath &&
        characterFilePath == data.npc.filepath
      ) {
        tempFilePath = null;
      } else if (
        data.npc &&
        data.npc.filepath == "" &&
        characterFilePath == data.npc.filepath
      ) {
        tempFilePath = null;
      } else {
        characterFilePath = "";
        tempFilePath = characterFilePath;
      }

      let tempBgImgUrl = characterBgImgUrl;
      if (
        data.npc &&
        data.npc.background &&
        characterBgImgUrl != data.npc.background
      ) {
        characterBgImgUrl =
          (data.npc && data.npc.background && data.npc.background) || "";
        tempBgImgUrl = characterBgImgUrl;
      } else if (
        data.npc &&
        data.npc.background &&
        characterBgImgUrl == data.npc.background
      ) {
        tempBgImgUrl = null;
      } else if (
        data.npc &&
        data.npc.background == "" &&
        characterBgImgUrl == data.npc.background
      ) {
        tempBgImgUrl = null;
      } else {
        characterBgImgUrl = "";
        tempBgImgUrl = characterBgImgUrl;
      }

      setUnity(tempFilePath, tempBgImgUrl);

      dataLoaded.value = true;

      if (toCabinetFromMessageBoard.value) {
        showCabinet.value = true;
        toCabinetFromMessageBoard.value = false;
      }

      if (!route.query.preview) {
        linkardNet.buryingPointPageDataReport(
          linkardId.value,
          route.fullPath,
          buryingPointData.GetBuryingPointDataInfo()
        );
      }
    };

    const handlePreview = (data: any) => {
      clearLinkardData();
      previewMediaList.value = data.socialLinks || [];
      path.value = data.path;

      sessionStorage.setItem(ACCESS_TOKEN, data.token);
      updateLinkardData(data, "handlePreview");
    };

    const loadData = async (routerPath: string) => {
      cabinetUserId.value = "";
      clearLinkardData();
      let hasToken = !!sessionStorage.getItem(ACCESS_TOKEN);
      if (!hasToken && route.query.code) {
        hasToken = await getLogin(String(route.query.code), String(route.query.P_code), String(route.query.P_data));
        const smalToken = String(route.query.smalToken);
        const tempReviewBookId = String(route.query.bookId);
        const tempReviewCourseId = String(route.query.courseId);

        if (smalToken && smalToken != undefined && smalToken != 'undefined') {
          sessionStorage.setItem(SMAL_TOKEN, smalToken);
        }

        if (tempReviewBookId && tempReviewBookId != undefined && tempReviewBookId != 'undefined') {
          reviewBookId.value = tempReviewBookId;
        }

        if (tempReviewCourseId && tempReviewCourseId != undefined && tempReviewCourseId != 'undefined') {
          reviewCourseId.value = tempReviewCourseId;
        }
      } else if (hasToken && !route.query.preview) {
        const tempUserId = sessionStorage.getItem("userId");
        userId = tempUserId ?? "";
        await retryGetSdkToken();
      }

      if (routerPath) {
        path.value = routerPath;
      }
      // showUnity = !isWeixin || hasToken;
      if (hasToken && !route.query.preview) {
        await getUserDetailRequest();
        if (!path.value && mySelfPath) {
          path.value = mySelfPath;
        }

        if (route.query.from === "chatPage") {
          showChat.value = true;
        } else if (route.query.from === "cabinet") {
          showCabinet.value = true;
        } else if (route.query.from === "leaveMessage") {
          showMessageBoardView.value = true;
        }
      }

      const linkardInfo = await getInfoRequest();
      linkardId.value = "";
      if (linkardInfo) {
        updateLinkardData(linkardInfo, "getInfoRequest");

        cabinetValid.value = linkardInfo.cabinetValid;
        if (
          linkardInfo.operation &&
          linkardInfo.operation.phone &&
          linkardInfo.operation.phone.includes("+86,")
        ) {
          userPhone.value = linkardInfo.operation.phone.replace("+86,", "");
        } else if (linkardInfo.operation && linkardInfo.operation.phone) {
          userPhone.value = linkardInfo.operation.phone;
        }

        const shareLinkardName = linkardInfo.name;
        const shareNpcName = linkardInfo.npc && linkardInfo.npc.npcName;
        useWxShare({
          title: `@${shareLinkardName}`,
          desc: `智能体${shareNpcName}带你了解${shareLinkardName}`,
          imgUrl: WX_SHARE_LOGO,
          link: `${process.env.VUE_APP_URL}/${path.value}`,
        });
      } else {
        dataLoaded.value = true;
      }

      if (route.query.code) {
        // 删除code
        const newRouteQuery = { ...route.query };
        delete newRouteQuery["code"];
        await router.replace({ query: newRouteQuery });
      }
    };

    const toChat = () => {
      if (route.query.preview) return;
      if (!sessionStorage.getItem(ACCESS_TOKEN)) {
        startLogin(path.value, { from: "chatPage" });
        return false;
      } else {
        showChat.value = true;
        chatLottie.value.playOrPause(false);
      }
    };

    const closeChatView = () => {
      chatLottie.value.playOrPause(true);
      showChat.value = false;
    };

    const closeMediaDialog = () => {
      showMedia.value = false;
    };

    const showMediaClick = () => {
      showMedia.value = true;
    };

    const getLogin = async (
      code: string,
      P_code: string,
      P_data: string,
      reloadSdkLogin = false
    ): Promise<boolean> => {
      try {
        const response = await linkardNet.login(code, 1, P_code, P_data);
        sessionStorage.setItem(ACCESS_TOKEN, response.token);
        userId = response.userId;
        sessionStorage.setItem("userId", response.userId);
        if (response.sdkToken != null) {
          audioSdkLoginRequest(response.userId, response.sdkToken.token);
        }
        return true;
      } catch (ex) {
        return false;
      }
    };

    const audioSdkLoginRequest = async (
      userId: string,
      sdkLoginToken: string
    ) => {
      audioSdkLoginCount += 1;
      const args: AudioSdkLoginArgs = {
        uid: userId,
        package:
          process.env.VUE_APP_ENV == "production"
            ? SDK_LOGIN_PACKAGE
            : SDK_LOGIN_PACKAGE_TEST,
        token: sdkLoginToken,
      };

      linkardNet.audioSdkLogin(args, sdkLoginToken).then(
        (response) => {
          sdkToken.value = response.token;
          streamId = response.streamId;
          if (!hasInitService.value) {
            const tempId = getGenerateId();
            initAudioServiceMethod(tempId.toString());
            hasInitService.value = true;
          }
        },
        (ex) => {
          if (audioSdkLoginCount <= 2) {
            retryGetSdkToken();
          }
        }
      );
    };

    const retryGetSdkToken = async () => {
      linkardNet.generateSdkToken().then(
        (response) => {
          audioSdkLoginRequest(userId, response.token);
        },
        (ex) => { }
      );
    };

    // 获取联卡信息
    const getInfoRequest = async (): Promise<CurrentLKInfo | null> => {
      try {
        if (path.value) {
          const response = await linkardNet.getInfo(path.value);
          currentLKInfo.value = response;
          return response;
        } else {
          return null;
        }
      } catch (ex) {
        return null;
      }
    };

    const handleSpaceVisit = async () => {
      if (!route.query.preview && spaceValid.value) {
        window.location.href = `${SERVER_API_URL}/v2/home/space/visit?id=${linkardId.value}`;
      }
    };

    const toMyLinkard = async () => {
      // if (!sessionStorage.getItem(ACCESS_TOKEN)) {
      //   return startLogin('', { 'loginType': 'clickMyLinkard' });
      // }
      // if (route.params.pathMatch !== mySelfPath) {
      //   await router.replace({ path: '/' + mySelfPath });
      // }
      showMyLinkardListView.value = true;
    };

    const toMyFavorites = () => {
      showFavorites.value = true;
    };

    const toMyCabinet = () => {
      if (!sessionStorage.getItem(ACCESS_TOKEN)) {
        startLogin(path.value, { from: "cabinet" });
        return false;
      } else {
        showCabinet.value = true;
      }
    };

    const closeMyFavorites = async (toPath: string) => {
      showFavorites.value = false;
      const path = toPath || currentLKInfo.value?.path;
      if (route.params.pathMatch !== path) {
        //interruptPlayVoiceMethod();//如果当前卡片正在说话中，切换卡片 中断当前卡片的语音
        await router.replace({ path: "/" + path });
      }
    };

    const closeMyLinkardListView = async (toPath: string) => {
      showMyLinkardListView.value = false;
      const path = toPath || currentLKInfo.value?.path;
      if (route.params.pathMatch !== path) {
        //interruptPlayVoiceMethod();//如果当前卡片正在说话中，切换卡片 中断当前卡片的语音
        await router.replace({ path: "/" + path });
      }
    };

    const closeCabinet = async (toPath: string) => {
      showCabinet.value = false;
      cabinetId.value = "";
      const path = toPath || currentLKInfo.value?.path;
      if (route.params.pathMatch !== path) {
        await router.replace({ path: "/" + path });
        interruptPlayVoiceMethod(); //如果当前卡片正在说话中，切换卡片 中断当前卡片的语音
      }
    };

    const closeLeaveMessage = async () => {
      showMessageBoardView.value = false;
    };

    const goToPathFromMessageBoard = async (item: any) => {
      showMessageBoardView.value = false;
      const path = item.path || mySelfPath;
      if (route.params.pathMatch !== path) {
        if (item.level == 3 || item.level == 4) {
          cabinetUserId.value = item.userId;
          toCabinetFromMessageBoard.value = true;
        }
        await router.replace({ path: "/" + path });
      }
    };

    const toLeaveMessage = () => {
      if (!sessionStorage.getItem(ACCESS_TOKEN)) {
        startLogin(path.value, { from: "leaveMessage" });
        return false;
      } else {
        showMessageBoardView.value = true;
      }
    };

    const isFavoriteRequest = async () => {
      linkardNet.isFavorite(linkardId.value).then(
        (response) => {
          favoriteStatus.value = response;
        },
        (ex) => { }
      );
    };

    const getUserDetailRequest = async () => {
      linkardNet.getUserDetail().then(
        (response) => {
          mySelfPath = "personal/" + response.path;
        },
        (ex) => {
          showToast({
            message: "获取用户信息失败",
            position: "top",
          });
        }
      );
    };

    const initAudioService = async (isFirst: boolean) => {
      if (route.query.preview) return;
      const requestId = getGenerateId();
      if (isFirst) {
        if (sdkToken) {
          initAudioServiceMethod(requestId.toString());
          hasInitService.value = true;
        } else {
          hasInitService.value = false;
        }
      } else {
        if (registerSetChatParamCallBack) {
          registerSetChatParamCallBack(
            npcId,
            voiceId,
            userId ? userId : requestId.toString()
          );
        }
      }
    };

    const initAudioServiceMethod = (tempId: string) => {
      if (initServiceCallBack) {
        initServiceCallBack(
          sdkToken.value,
          userId ? userId : tempId,
          streamId != "" ? streamId : tempId
        );
      }
      if (registerSetChatParamCallBack) {
        registerSetChatParamCallBack(
          npcId,
          voiceId,
          userId ? userId : tempId.toString()
        );
      }
    };

    const resetRegisterSetChatParam = (tempNpcId: string) => {
      if (registerSetChatParamCallBack) {
        const tempId = getGenerateId();
        registerSetChatParamCallBack(
          tempNpcId,
          voiceId,
          userId ? userId : tempId.toString()
        );
      }
    };

    const getGenerateId = () => {
      const timestamp = new Date().getTime(); // 获取当前时间戳
      const timestampStr = timestamp.toString();
      const truncatedStr = timestampStr.substring(5); // 去掉前三位
      return parseInt(truncatedStr, 10); // 将截取后的字符串转换为整数
    };

    const handleCollect = async () => {
      if (!sessionStorage.getItem(ACCESS_TOKEN)) {
        await showConfirmDialog({
          title: "提示",
          message: "登陆后才能收藏",
        });
        startLogin(path.value, {});
      } else {
        if (linkardId.value) {
          linkardNet.addById(linkardId.value).then(
            (response) => {
              favoriteStatus.value = true;
              showToast({
                message: "已添加至我的卡包",
                position: "top",
              });
            },
            (ex) => { }
          );
        }
      }
    };

    const handleCancelCollect = async () => {
      if (!sessionStorage.getItem(ACCESS_TOKEN)) {
        await showConfirmDialog({
          title: "提示",
          message: "登陆后才能取消收藏",
        });
        startLogin(path.value, {});
      } else {
        if (linkardId.value) {
          linkardNet.removeById(linkardId.value).then(
            (response) => {
              favoriteStatus.value = false;
              showToast({
                message: "已取消收藏",
                position: "top",
              });
            },
            (ex) => { }
          );
        }
      }
    };

    const startLogin = (path: string, params: any) => {
      const url = composeLoginUrl(path, params);
      window.location.href = url;
    };

    const phoneCall = () => {
      // 构建拨号链接
      var dialUrl = "tel:" + encodeURIComponent(userPhone.value);
      // 通过修改 window.location.href 进行拨号操作
      window.location.href = dialUrl;
    };

    const countdownAction = () => {
      if (!welcomeMsg.value) return;
      showWelcomeMsg.value = true;
      const dismissCount = 5000;

      function clearWelcomeMsg() {
        welcomeMsg.value = "";
        showWelcomeMsg.value = false;
      }

      if (controlAdKeyShowTimer == 0) {
        controlAdKeyShowTimer = setTimeout(clearWelcomeMsg, dismissCount);
      } else {
        clearTimeout(controlAdKeyShowTimer);
        controlAdKeyShowTimer = 0;
        controlAdKeyShowTimer = setTimeout(clearWelcomeMsg, dismissCount);
      }
    };

    const getMicPermissions = () => {
      unityIframe.value.contentWindow.getLocalAudioPermissions(
        (permission: any) => {
          if (permission) {
            micPermissions.value = 1;
          } else {
            micPermissions.value = -1;
          }
        }
      );
    };

    const setUnity = (filePath: any, bgImage: any) => {
      if (setUnityTimer) {
        clearInterval(setUnityTimer);
        setUnityTimer = 0;
      }

      const InitUnityInfo = () => {
        unityInstance =
          unityIframe && unityIframe.value.contentWindow._unityInstance_;
        if (unityInstance) {
          if (!route.query.preview) {
            unityIframe.value.contentWindow.receiveChatResponseMethod = (
              errorCode: any,
              requestId: any
            ) => {
              chatViewRef.value.receiveChatResponse(errorCode, requestId);
            };

            unityIframe.value.contentWindow.updateChatTextMethod = (
              requestId: any,
              message: any
            ) => {
              chatViewRef.value.updateChatText(requestId, message);
            };

            unityIframe.value.contentWindow.updateSTTTextMethod = (
              requestId: any,
              message: any
            ) => {
              chatViewRef.value.updateSTTTextMethod(requestId, message);
            };

            unityIframe.value.contentWindow._webrtcConnectionStateCallback = (
              state: number
            ) => {
              chatViewRef.value.webrtcConnectionStateMethod(state);
            };

            unityIframe.value.contentWindow.audioPlayResponseMethod = (
              playCode: any
            ) => {
              chatViewRef.value.audioPlayResponse(playCode);
            };
          }
          setTimeout(() => {
            countdownAction();
          }, 500);
          if (!route.query.preview) {
            initServiceCallBack =
              unityIframe.value.contentWindow.initServiceCallBack;
            interruptPlayVoiceCallBack =
              unityIframe.value.contentWindow.interruptPlayVoiceCallBack;
            setSendAudioEnableCallBack =
              unityIframe.value.contentWindow.setSendAudioEnableCallBack;
            registerSetReviewCourseCallback =
              unityIframe.value.contentWindow.registerSetReviewCourseCallback;
            registerSetChatParamCallBack =
              unityIframe.value.contentWindow.registerSetChatParamCallBack;
            startCaptureAudioCallBack =
              unityIframe.value.contentWindow.startCaptureAudioCallBack;
            stopCaptureAudioCallBack =
              unityIframe.value.contentWindow.stopCaptureAudioCallBack;
            sendTextToMouthCallBack =
              unityIframe.value.contentWindow.sendTextToMouthMethod;
            initUnityCallBack.value = true;
          }
          initAudioService(true);
          setUnityModel(unityInstance, filePath);
          setUnitBackGroundImage(unityInstance, bgImage);

          clearInterval(setUnityTimer);
          setUnityTimer = 0;
        }
      };
      if (initUnityCallBack.value) {
        setTimeout(() => {
          countdownAction();
        }, 500);
        initAudioService(false);
        unityInstance =
          unityIframe && unityIframe.value.contentWindow._unityInstance_;
        setUnityModel(unityInstance, filePath);
        setUnitBackGroundImage(unityInstance, bgImage);
      } else {
        setUnityTimer = setInterval(InitUnityInfo, 50);
      }
    };

    const setUnityModel = (unitIns: any, filePath: string) => {
      if (
        unitIns != null &&
        filePath != "undefined" &&
        filePath != undefined &&
        filePath != null
      ) {
        unitIns.SendMessage("Script", "SetCurModel", filePath);
      }
    };

    const setUnitBackGroundImage = (unitIns: any, bgImage: string) => {
      if (
        unitIns != null &&
        bgImage != "undefined" &&
        bgImage != undefined &&
        bgImage != null
      ) {
        unitIns.SendMessage("Script", "ChangeBackGroundImage", bgImage);
      }
    };

    handleChatSortedKeys();
    if (!cabinetId.value || cabinetId.value == "undefined") {
      loadData(route.params.pathMatch as string);
    } else {
      dataLoaded.value = true;
    }
    window.addEventListener("message", (event) => {
      try {
        if (event && event.data && event.data.dataType === "linkard") {
          handlePreview(event.data);
        }
      } catch (e) { }
    });

    const showEmptyCard = computed(() => {
      return linkardId.value.length <= 0 && !showCabinet.value;
    });

    const isMySelfPath = computed(() => {
      return path.value != null && path.value.length > 0 && isOwn.value;
    });

    const startCaptureAudioMethod = (audioRequstId: string) => {
      if (startCaptureAudioCallBack) {
        startCaptureAudioCallBack(audioRequstId);
      }
    };

    const interruptPlayVoiceMethod = () => {
      if (interruptPlayVoiceCallBack) {
        interruptPlayVoiceCallBack();
      }
    };

    const stopCaptureAudioMethod = (timeIsShort: boolean) => {
      if (stopCaptureAudioCallBack) {
        stopCaptureAudioCallBack(timeIsShort);
      }
    };

    const sendTextToMouthCallBackMethod = (requestId: string, text: string) => {
      if (sendTextToMouthCallBack) {
        sendTextToMouthCallBack(requestId, text);
      }
    };

    const setSendAudioEnableMethod = (enable: boolean) => {
      if (setSendAudioEnableCallBack) {
        setSendAudioEnableCallBack(enable);
      }
    };

    // const registerSetReviewCourseCallbackMethod = (ids: string[]) => {
    //   if (registerSetReviewCourseCallback) {
    //     registerSetReviewCourseCallback(ids, true);
    //   }
    // };

    onBeforeRouteUpdate((to, from, next) => {
      next();
      if (from.params.pathMatch !== to.params.pathMatch) {
        loadData(to.params.pathMatch as string);
      }
    });

    const showChatMessageListMethod = () => {
      showChat.value = false;
      showChatMessageList.value = true;
    };

    const showMyEvaluationListMethod = () => {
      showChat.value = false;
      showMyEvaluationList.value = true;
    };

    const closeChatMessageListView = () => {
      showChat.value = true;
      showChatMessageList.value = false;
    };

    const closeMyEvaluationListView = () => {
      showChat.value = true;
      showMyEvaluationList.value = false;
    };

    const showEvaluationDetailView = (reportId: string, showType: number) => {
      evalReportId.value = reportId;
      evlDetailShowType = showType;
      if (showType == 1) {
        showEvaluationDetail.value = true;
        showMyEvaluationList.value = false;
      } else {
        showChat.value = false;
        showEvaluationDetail.value = true;
      }
    };

    const closeEvaluationDetailView = () => {
      if (evlDetailShowType == 1) {
        showEvaluationDetail.value = false;
        showMyEvaluationList.value = true;
      } else {
        showEvaluationDetail.value = false;
        showChat.value = true;
      }
    };

    onMounted(() => { });

    return {
      backgroundImg: require("../assets/image/default-body-bg.svg"),
      showChat,
      showMedia,
      linkardTags,
      linkardId,
      spaceValid,
      favoriteStatus,
      path,
      companyLogo,
      previewMediaList,
      linkardName,
      welcomeMsg,
      showWelcomeMsg,
      unityInstance,
      initServiceCallBack,
      registerSetChatParamCallBack,
      startCaptureAudioCallBack,
      stopCaptureAudioCallBack,
      interruptPlayVoiceCallBack,
      setSendAudioEnableCallBack,
      characterFilePath,
      characterBgImgUrl,
      mySelfPath,
      showFavorites,
      showSocialLinksNew,
      controlAdKeyShowTimer,
      spaceName,
      setUnityTimer,
      voiceId,
      npcId,
      userId,
      sdkToken,
      micPermissions,
      audioSdkLoginCount,
      streamId,
      hasInitService,
      initUnityCallBack,
      showMessageBoardView,
      showCabinet,
      isOwn,
      cabinetValid,
      messageValid,
      userPhone,
      toCabinetFromMessageBoard,
      cabinetUserId,
      chatLottie,
      unityIframe,
      chatViewRef,
      currentLKInfo,
      showChatMessageList,
      showMyLinkardListView,
      cabinetId,
      isHarmonyos,
      showMyEvaluationList,
      showEvaluationDetail,
      evalReportId,
      reviewBookId,
      reviewCourseId,
      toMyLinkard,
      toMyFavorites,
      handleCollect,
      handleCancelCollect,
      toMyCabinet,
      phoneCall,
      toLeaveMessage,
      toChat,
      handleSpaceVisit,
      closeMediaDialog,
      closeChatView,
      getMicPermissions,
      closeMyFavorites,
      closeMyLinkardListView,
      closeLeaveMessage,
      goToPathFromMessageBoard,
      closeCabinet,
      showMediaClick,
      startCaptureAudioMethod,
      interruptPlayVoiceMethod,
      stopCaptureAudioMethod,
      setSendAudioEnableMethod,
      showChatMessageListMethod,
      closeChatMessageListView,
      sendTextToMouthCallBackMethod,
      closeMyEvaluationListView,
      showMyEvaluationListMethod,
      closeEvaluationDetailView,
      showEvaluationDetailView,
      resetRegisterSetChatParam,
      dataLoaded,
      showEmptyCard,
      isMySelfPath,
      sourceType,
      LOCAL_UNITY_URL_PATH,
      LOCAL_UNITY_HM_URL_PATH,
    };
  },
});
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  background: #dedede;
  overflow: hidden;

  .van-overlay {
    border-radius: 28px;
    background: rgb(240, 240, 240);
    width: calc(100% - 20px);
    height: calc(100% - 44px);
    border: 2px solid #242424;
    margin: 16px 0 0 10px;
    z-index: 2001;
  }
}

.share-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  .close {
    width: 24px;
    height: 24px;
    margin-left: auto;
    margin-right: 32px;
    margin-bottom: 16px;
  }

  .post {
    width: 320px;
    height: 450px;
    margin-bottom: 32px;
  }

  button {
    width: 327px;
    height: 48px;
    border-radius: 30px;
    background: #f3ec3f;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border: none;
  }
}

.home-container {
  background: #dedede;
  padding-bottom: 0;
  background-repeat: no-repeat;
  height: 100%;
  padding-top: 0;
  background-size: cover;

  .linkard-config-tost {
    width: 100%;
    position: absolute;
    z-index: 10;
    font-size: 12px;
    color: #242424;
    font-weight: 600;
    background: #f3ec3f;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    padding: 6px 0;
    text-align: center;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
  }

  .unity-iframe-container {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 16px;
    bottom: 28px;
    box-sizing: border-box;
    background: #221f20;
    border-radius: 28px;

    .unity-iframe {
      width: 100%;
      height: 100%;
      border-radius: 28px;
      border: medium none;
      background: #221f20;
    }
  }

  .unity-iframe-container-minimalism {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    // bottom: 28px;
    bottom: 0px;
    overflow: hidden;
    box-sizing: border-box;
    background: #221f20;
    border-radius: 0px;

    .unity-iframe {
      width: 100%;
      height: 100%;
      border-radius: 0;
      border: medium none;
      background: #221f20;
    }
  }

  .content {
    position: absolute;
    top: 16px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-content: flex-start;

    .content-wrapper {
      overflow: hidden;
      flex: 1;

      .header-link {
        padding: 24px 20px 0 20px;
        display: flex;
        align-items: center;
        color: #f3ec3f;
        font-size: 10px;
        font-weight: 600;
        width: 100%;

        label {
          font-weight: 700;
        }

        span:first-child {
          margin-right: 25px;
        }

        span:nth-child(2) {
          width: 72px;
          height: 1px;
          background: #f3ec3f;
          margin-right: 16px;
        }

        span:last-child {
          border-radius: 16px;
          color: #fff;
          font-size: 8px;
          margin-left: auto;
          margin-right: 50px;

          a {
            color: #242424;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              display: flex;
              align-items: center;
              margin-top: 1px;
            }

            img {
              margin-right: 2px;
              width: 8px;
              height: 8px;
            }
          }
        }
      }

      .card-collect-main {
        width: 100%;
        display: flex;
        align-items: flex-start;
        font-size: 12px;
        margin-top: 14px;
        justify-content: space-between;

        .home-card-top-action-box {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          color: #f0f0f0;
          background: #242424;
          width: 104px;
          height: 48px;
          border: 1px solid #f0f0f0;
          border-left: none;
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;

          .van-image {
            width: 10px;
            margin: 0 10px;
          }
        }

        .card_box {
          margin-top: 6px;
        }

        .right_action_button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin-right: 20px;

          .collect-box {
            width: 64px;
            color: #242424;
            padding: 7px 0px;
            background: #f3ec3f;
            font-weight: 500;
            border-radius: 30px;
            text-align: center;
          }

          .had-collect {
            width: 64px;
            background: #242424;
            color: #f0f0f0;
            text-align: center;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
          }

          .cabinet_box {
            width: 64px;
            height: 64px;
            border-radius: 12px;
            background: #f0f0f0;
            margin-top: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #242424;
            font-size: 10px;
            font-weight: 600;

            img {
              width: 18px;
              height: 22px;
              margin-bottom: 6px;
            }
          }

          .cabinet_box:active {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .bottom_info_bg {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 28px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    pointer-events: none;

    .bar-con {
      width: calc(100% - 24px);
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 8px;
      pointer-events: none;

      .umi-head {
        width: 40px;
        height: 40px;
      }

      :deep(.umi-head1) {
        width: 50px;
      }

      .talk-input {
        box-sizing: border-box;
        width: auto;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: start;
        padding: 12px 16px;
        background: #f2f2f2;
        border-radius: 20px;
        font-size: 12px;
        color: #000;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;

        .welcome_msg {
          width: auto;
          line-height: 16px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          text-overflow: ellipsis;
          text-align: left;
          max-height: 102px;
        }
      }

      .talk-btn {
        width: 78px;
        height: 36px;
        background: #f3ec3f;
        border-radius: 20px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    .adver-chat-main {
      left: 0;
      right: 0;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: 12px;

      .adver-box {
        display: flex;

        .adver-box-item {
          color: var(--Black, #242424);
          width: auto;
          height: 40px;
          border-width: 0;
          background: #f2f2f2;
          border-radius: 30px;
          padding: 10px 18px;
          margin-right: 8px;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          pointer-events: auto;
        }
      }

      .chat-box {
        width: 108px;
        height: 36px;
        border-width: 0;
        padding: 0;
        background: #f3ec3f;
        border-radius: 20px;
        font-size: 12px;
        color: #242424;
        font-weight: 600;
        pointer-events: auto;
      }
    }

    .content-middle {
      padding: 28px 28px 20px 28px;
      border: 2px solid #242424;
      background: #f0f0f0;
      border-radius: 28px;
      pointer-events: auto;

      .middle-bg {
        display: flex;

        .logo_img {
          width: 82px;
          height: 82px;
          margin-right: 26px;
          border-radius: 98px;
          object-fit: cover;
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        }

        .middle-content {
          text-align: left;
          font-weight: 500;
          overflow: hidden;

          .social-media-box {
            height: 28px;
            padding: 0 8px 0 12px;
            background: #242424;
            border-radius: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: start;
            margin-top: 10px;
            color: #fff;

            .social-media-text {
              color: #f0f0f0;
              font-weight: 600;
              font-size: 12px;
            }

            .social_links_new {
              width: auto;
              height: 28px;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 4px;
            }

            .social-media-icon {
              width: 10px;
              height: 10px;
              background: #f3ec3f;
              border-radius: 50%;
              margin-right: 4px;
            }

            .social-media-new {
              color: #f3ec3f;
              font-weight: 600;
              font-size: 8px;
              height: 10px;
              text-align: center;
              line-height: normal;
            }

            .social_media_arrow {
              width: 10px;
              height: 10px;
              margin-left: 4px;
            }
          }

          .title {
            font-size: 23px;
            margin-bottom: 6px;
            font-weight: 600;
            font-family: LXGW 975 HazyGo SC;
          }

          .des {
            font-size: 10px;
            height: 10px;
            margin-bottom: 6px;
            font-family: Quantico;
          }

          .btn-wrapper {
            // line-height: 14px;
            display: flex;

            span {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 55px;
              // padding: 1px 0;
              height: 14px;
              border-radius: 8px;
              border: 1px solid #000;
              margin-right: 8px;
              font-family: LXGW 975 HazyGo SC;
              font-size: 8px;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }

      .space-box {
        display: flex;
        align-items: center;
        font-size: 14px;
        border: 1px solid #242424;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        background: #fff;
        border-radius: 32px;
        margin: 16px 5px 0 5px;
        justify-content: space-between;
        color: #242424;
        font-weight: 600;
        height: 44px;

        .space-box-3d {
          padding-left: 24px;
        }

        .van-image {
          width: 18px;
          padding-right: 36px;
        }
      }

      .no-space-box {
        display: flex;
        align-items: center;
        font-size: 14px;
        background: #ccc;
        border-radius: 32px;
        margin: 20px 5px 0 5px;
        justify-content: space-between;
        color: #f0f0f0;
        font-weight: 600;
        height: 44px;
        border-width: 0;
      }

      .space-box:active {
        opacity: 0.6;
      }

      .contact-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
      }
    }
  }

  .content-pt0 {
    padding-top: 0;
  }

  .media-middle-bg {}

  .media-space-box {
    margin-top: 40px !important;
  }
}

@keyframes slideup {
  0% {
    transform: translateY(3%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.arrow {
  position: relative;
  display: inline-block;
  animation: shake 2s ease-in-out infinite;
}

@keyframes shake {
  0% {
    transform: translateX(60%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.opcity {
  position: relative;
  display: inline-block;
  animation: opcityItem 2s ease-in-out infinite;
  color: #242424;
}

@keyframes opcityItem {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes heightChange {
  0% {
    height: 28px;
    transform: translate(0px, 0px);
  }

  50% {
    height: 48px;
    transform: translate(0px, -16px);
  }

  100% {
    height: 28px;
    transform: translate(0px, 0px);
  }
}
</style>

<style>
@keyframes hidetip {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 2s;
}

.fade-enter,
.fade-leave-to {
  transform: scale(0.1);
  opacity: 0;
}
</style>
