<template>
    <div class="Bubble">
      <BubbleItem v-for="(bubble, index) in validMenuItems.slice(0, 5)" :key="index" :position="positions[index%5]" :delay="randomDelay[index%5]" :text="bubble.name" @sendTexttoChat ="sendTexttoChat">
      </BubbleItem>
    </div>
  </template>
  
  <script lang="ts">
  import BubbleItem from './bubbleItem.vue'
  import {defineComponent, ref, onMounted,PropType,computed } from 'vue';
  import { SubMenuItem,ChatMenuItem } from "@/net/linkardNet";
  interface BubbleType {
    position: string;
    text: string;
    delay: number;
  }

  export default defineComponent({
    props: {
      chatMenuList: {
        type: Array as PropType<any[]>,
        default: [],
      }
   },
    name: 'Bubble',
    components: {
        BubbleItem
    },
    setup(props, { emit }) {
      const positions = ['top-left','top-left2', 'top-right', 'bottom-left', 'bottom-right'];
      const randomDelay = [0.367,0.584,0,2698,0.682,0,762];
      const bubbles =  ref<BubbleType[]>([]);
      const validMenuItems = computed(() => {
      return (props.chatMenuList || []).filter((item): item is ChatMenuItem => {
        return item && (typeof item.subMenuList === undefined || item.subMenuList?.length==0)
      });
    });

      const sendTexttoChat =(text:String) =>{
        emit("sendTexttoChat",text);
      }
      // const generateBubbles = () => {
      //   let max = 0;
      //   max = validMenuItems.value.length;
      //   for (let i = 0; i < max; i++) {
      //   //  const randomPosition = positions[Math.floor(Math.random() * positions.length)];
      //     const randomDelay = Math.random(); 
      //     const randomText = `Bubble ${i + 1}`; 
      //     console.log("JoyLove"+randomText)
      //     bubbles.value.push({ position: positions[i], text:validMenuItems.value[i].name, delay: randomDelay });
      //   }
      // };

      onMounted(() => {
      //  generateBubbles();
      });

      return {
        sendTexttoChat,
        bubbles,
        validMenuItems,
        randomDelay,
        positions
       
      };
    }
  })
  </script>
  
  <style>
  .Bubble {
    width: 100%;
    height: 100%;
  }
  </style>