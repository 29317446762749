<template>
    <div class="star-field">
      <div v-for="star in stars" :key="star.id" :style="star.style" class="star"></div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from 'vue';
  
  export default {
    name: 'StarField',
    setup() {
      const stars = ref([]);
  
      const createStars = () => {
        for (let i = 0; i < 100; i++) {
          stars.value.push({
            id: i,
            style: {
              left: `${Math.random() * 100}vw`,
              top: `${Math.random() * 100}vh`,
              animationDuration: `${Math.random() * 10 + 2}s`,
              transform: `scale(${Math.random() * 0.5 + 0.5})`,
              animationDelay: `${Math.random() * 5}s`, // 随机延迟闪烁动画
              opacity: Math.random() * 0.5 + 0.5 // 初始透明度
            }
          });
        }
      };
  
      onMounted(() => {
        createStars();
      });
  
      return {
        stars
      };
    }
  };
  </script>
  
  <style scoped>
  .star-field {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .star {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 2px;
    height: 2px;
    animation: fall linear infinite, blink ease-in-out infinite;
  }
  
  @keyframes fall {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100vh);
    }
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }
  </style>